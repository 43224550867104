import React, { Component } from 'react'
import Modal from './Modal'
import DragAndDropList from './DragAndDropList'
import { InputSwitch } from 'primereact/inputswitch'
import { getTableConfig, setTableConfig } from '../../helpers/tableConfigs'
import { showSuccessMessage, showWarnMessage } from './Message'
import AppButton from '../layout/AppButton'

export default class TablePreferences extends Component {
  constructor (props) {
    super(props)

    this.state = {
      options: []
    }
  }

  componentDidUpdate (prevProps) {
    if (this.visibilidadeDoModalFoiModificada(prevProps)) {
      this.setOptions()
    }
  }

  visibilidadeDoModalFoiModificada (prevProps) {
    return prevProps.visible !== this.props.visible
  }

  setOptions () {
    if (this.isPreferenciasAtuaisConfigurada()) {
      this.setOptionsFromPreferenciasAtuais()
    } else {
      this.setDefaultOptions()
    }
  }

  isPreferenciasAtuaisConfigurada () {
    return !!getTableConfig(this.props.tableName)
  }

  setOptionsFromPreferenciasAtuais () {
    const options = this.getOptionsFromPreferenciasAtuais()
    this.handleChangeOptions(options)
  }

  getOptionsFromPreferenciasAtuais () {
    const props = this.props

    const preferenciasAtuais = getTableConfig(props.tableName)

    const options = preferenciasAtuais.map(column => {
      const preferenciaColuna = props.columns?.find(preferencia => preferencia.key === column.key)
      return { label: preferenciaColuna.label, key: column.key, enabled: preferenciaColuna.enabled ? column.enabled : false }
    })

    return options
  }

  setDefaultOptions (callBack) {
    const defaultOptions = this.props.columns.map(column => (
      {
        label: column.label,
        key: column.key,
        enabled: column.enabled
      }))

    this.handleChangeOptions(defaultOptions, callBack)
  }

  handleChangeOptions = (options, callBack) => {
    this.setState({ options }, callBack)
  }

  handleVoltarParaDefault () {
    this.setDefaultOptions(this.handleSave)
  }

  handleSave = () => {
    const props = this.props
    const state = this.state

    const novaPreferencia = state.options.map(option => ({ key: option.key, enabled: option.enabled }))

    if (novaPreferencia.length === 0) {
      showWarnMessage('Ao menos 1 campo deve estar habilitado!')
      return
    }

    setTableConfig(props.tableName, novaPreferencia)

    if (props.reportTable) {
      window.location.reload()
      return
    }

    props.hide()

    showSuccessMessage('As preferências da tabela foram atualizadas com sucesso!')
  }

  render () {
    const optionTemplate = (column) => (
      <div className="flex justify-content-between align-options table-preferences-container">
        <p className="p-text-bold no-margin table-preferences-label">{column.label}</p>
        <InputSwitch
          className="table-preferences-switch"
          checked={column.enabled}
          onChange={(e) => {
            const { options } = this.state
            options.find(option => option.key === column.key).enabled = e.value
            this.setState({ options })
          }} />
      </div>
    )

    return (
      <Modal
        header={this.props.title}
        width={35}
        visible={this.props.visible}
        modal={true}
        onHide={this.props.hide}
        footer={
          <>
            <AppButton
              type="button"
              label="Voltar para o padrão"
              className="p-button mt-3 mb-2 mr-3"
              onClick={() => this.handleVoltarParaDefault()}
            />
            <AppButton
              type="button"
              label="Salvar"
              className="p-button mt-3 mb-2"
              onClick={() => this.handleSave()}
            />
          </>
        }
      >
        <DragAndDropList
          items={this.state.options}
          itemTemplate={optionTemplate}
          onChangeItems={this.handleChangeOptions}
        />
	    </Modal>
    )
  }
}
