import React from 'react'
import EmpresasAcessoTreeSelect from '../../../../../components/inputs/empresasAcessoTreeSelect/EmpresasAcessoTreeSelect'
import SearchInput from '../../../../../components/inputs/searchInput/SearchInput'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import ModeloProcessoService from '../../../../../services/processo/ModeloProcessoService'
import SancesTreeSelect from '../../../../../components/inputs/TreeSelect'
import CheckboxInput from '../../../../../components/inputs/CheckboxInput'
import TextInput from '../../../../../components/inputs/TextInput'
import StatusProcessoService from '../../../../../services/processo/StatusProcessoService'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import { formatDocument } from '../../../../../helpers/formaters'

const ExpandedFiltersTemplateForAcompanhamento = (props) => {
  // * Necessário para controle do input checkbox
  const [controleCheckbox, setControleCheckbox] = React.useState(!!props?.state?.processos_por_usuario_logado)

  return (
    <>
      <div className="field col-12 md:col-6">
        <EmpresasAcessoTreeSelect
          label="Empresas"
          comecaSemNada
          selectedOptions={props.state.selectedOptionsEmpresas}
          changeSelectedOptions={async (selectedOptions) => {
            await props.setState((prevState) => ({
              ...prevState,
              selectedOptionsEmpresas: selectedOptions
            }))
          }}
          changeSelectedEmpresas={async (empresas) => {
            await props.setState((prevState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                empresas: empresas.map(empresa => empresa.codigo_pes)
              }
            }))
          }}
        />
      </div>
      <div className='field col-12 md:col-6'>
        <SancesTreeSelect
          label="Processos"
          groupLabel="Processos"
          campoChildrenLabel="descri_prc"
          modelService={ModeloProcessoService}
          placeholder="Selecione os processos"
          comecaSemNada
          selectedOptions={props.state.selectedOptionsProcessos}
          changeSelectedOptions={async (selectedOptions) => {
            await props.setState((prevState) => ({
              ...prevState,
              selectedOptionsProcessos: selectedOptions
            }))
          }}
          changeSelectedDados={async (processos) => {
            await props.setState((prevState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                processos: processos.map(processo => processo.codigo_prc)
              }
            }))
          }}
        />
      </div>
      <div className={'field col-12 md:col-6'}>
        <SearchInput
          // * AutoComplete Props Padrões
          field="nomraz_pes"
          label="Atendente"
          value={props.state?.atendente ? props.state?.atendente?.nomraz_pes : ''}
          placeholder="Nome do Atendente"
          minLength='1'
          onSelect={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              atendente: e.value,
              filter: {
                ...prevState.filter,
                atendente: e.value?.codigo_ven
              }
            }))
          }}
          className={ 'inputfield w-full'}
          suggestions={props.sugestaoAtendente}
          completeMethod={props.sugerirAtendente}
          onChange={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              atendente: { nomraz_pes: e.target.value },
              filter: { ...prevState.filter, atendente: null }
            }))
          }}

          // * FiltroModal
          filtrotitle="Pesquisa de atendente"
          service={VendedoresService}
          model={VendedorModel}
          primarykeyname="codigo_ven"
          columns={[
            { campo: 'codigo_ven', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' }
          ]}
        />
      </div>
      <div className={'field col-12 md:col-6'}>
        <SearchInput
          // * AutoComplete Props Padrões
          field="nomraz_pes"
          label="Cliente"
          value={props.state?.cliente ? props.state?.cliente?.nomraz_pes : ''}
          placeholder="Nome do cliente"
          minLength='1'
          onSelect={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              cliente: e.value,
              filter: {
                ...prevState.filter,
                cliente: e.value?.codigo_ven
              }
            }))
          }}
          className={ 'inputfield w-full'}
          suggestions={props.sugestaoCliente}
          completeMethod={props.sugerirCliente}
          onChange={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              cliente: { nomraz_pes: e.target.value },
              filter: { ...prevState.filter, cliente: null }
            }))
          }}

          // * FiltroModal
          filtrotitle="Pesquisa de cliente"
          service={PessoaService}
          model={PessoaModel}
          primarykeyname="codigo_pes"
          columns={[
            { campo: 'codigo_pes', nome: 'Código' },
            { campo: 'nomraz_pes', nome: 'Nome' },
            { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
          ]}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Negociação"
          placeholder="Negociação"
          value={props.state?.descri_negociacao}
          className="inputfield w-full"
          onChange={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              descri_negociacao: e.target.value,
              filter: { ...prevState.filter, descri_negociacao: e.target.value }
            }))
          }}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Veículo"
          placeholder="Veículo"
          value={props.state?.descri_veiculo}
          className="inputfield w-full"
          onChange={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              descri_veiculo: e.target.value,
              filter: { ...prevState.filter, descri_veiculo: e.target.value }
            }))
          }}
        />
      </div>
      <div className="field col-12 md:col-4">
        <TextInput
          label="Observação"
          placeholder="Observação"
          value={props.state?.descri_obs}
          className="inputfield w-full"
          onChange={(e) => {
            props.setState((prevState) => ({
              ...prevState,
              descri_obs: e.target.value,
              filter: { ...prevState.filter, descri_obs: e.target.value }
            }))
          }}
        />
      </div>
      <div className='field col-12 md:col-4'>
        <SancesTreeSelect
          label="Situação"
          groupLabel="Situação"
          campoChildrenLabel="descri_spr"
          modelService={StatusProcessoService}
          placeholder="Selecione as situações"
          selectedOptions={props.state.selectedOptionsSituacao}
          changeSelectedOptions={async (selectedOptions) => {
            await props.setState((prevState) => ({
              ...prevState,
              selectedOptionsSituacao: selectedOptions
            }))
          }}
          changeSelectedDados={async (situacoes) => {
            await props.setState((prevState) => ({
              ...prevState,
              filter: {
                ...prevState.filter,
                situacoes: situacoes.map(processo => processo.codigo_spr)
              }
            }))
          }}
        />
      </div>
      <div className="mb-2 flex align-items-center col-12 md:col-4 ">
        <CheckboxInput
          label="Apenas processos com pendências em minha responsabilidade"
          value={controleCheckbox}
          checked={controleCheckbox}
          onChange={async (e) => {
            await props.setState((prevState) => ({
              ...prevState,
              processos_por_usuario_logado: e.target.checked,
              filter: { ...prevState.filter, processos_por_usuario_logado: e.target.checked }
            }))
            setControleCheckbox(e.target.checked)
          }}
        />
      </div>
    </>
  )
}

export default ExpandedFiltersTemplateForAcompanhamento
