import React, { useState, useEffect } from 'react'
import Modal from '../Modal'
import AppButton from '../../layout/AppButton'
import TextInput from '../../inputs/TextInput'

const ModalMotivoCancelamento = ({ visible, onConfirm, onCancel }) => {
  const [motivo, setMotivo] = useState('')

  const handleConfirm = () => {
    onConfirm(motivo)
  }

  const handleCancel = () => {
    onCancel()
  }

  useEffect(() => {
    if (!visible) setMotivo('')
  }, [visible])

  return (
    <Modal
      header="Motivo do cancelamento"
      visible={visible}
      width="30"
      modal={true}
      onHide={handleCancel}
    >
      <div className="field col-12">
        <TextInput
          value={motivo}
          label="Motivo"
          onChange={(e) => setMotivo(e.target.value)}
          className="inputfield w-full"
        />
      </div>

      <div className="p-dialog-buttons">
        <AppButton
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-danger mr-2"
          onClick={handleCancel}
        />
        <AppButton
          label="Confirmar"
          icon="pi pi-check"
          onClick={handleConfirm}
        />
      </div>
    </Modal>
  )
}

export default ModalMotivoCancelamento
