import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import MotivoVendaPerdidaService from '../../services/vendas/MotivoVendaPerdidaService'
import SelectInput from '../inputs/SelectInput'
import { showWarnMessage } from './Message'
import Modal from './Modal'

function MotivoVendaPerdidaModal ({ visible, onHide, onConfirm }) {
  const [motivoSelecionado, setMotivoSelecionado] = useState(null)
  const [motivosVendaPerdida, setMotivosVendaPerdida] = useState([])

  useEffect(() => {
    if (!visible) return
    getMotivosVendaPerdida()
  }, [visible])

  async function getMotivosVendaPerdida () {
    const motivosVendaPerdidaService = await MotivoVendaPerdidaService.getAll()
    setMotivosVendaPerdida(motivosVendaPerdidaService)
  }

  async function handleConfirm () {
    if (!motivoSelecionado) {
      showWarnMessage('Por favor selecione um motivo de venda perdida!')
      return
    }

    await onConfirm(motivoSelecionado)

    onHide()
  }
  return (
    <Modal
      visible={visible}
      onHide={onHide}
      header="Motivo da venda perdida"
      width={40}
      footer={
        <Button
          label="Confirmar"
          onClick={async () => await handleConfirm()}
        />
      }
    >
      <div className="formgrid grid fluid">
        <div className="field col-12">
          <SelectInput
            label="Motivo da venda perdida"
            dataKey="codigo_mcv"
            optionLabel="descri_mcv"
            value={motivoSelecionado}
            options={motivosVendaPerdida}
            onChange={(e) => setMotivoSelecionado(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default MotivoVendaPerdidaModal
