import * as Yup from 'yup'

export const EtapaProcessoValidator = Yup.object().shape({
  etapa_responsabilidade: Yup.object()
    .shape({
      codigo_etr: Yup.number()
        .nullable()
        .required('Campo responsável da etapa é obrigatório!')
    })
    .nullable(),
  responsavel: Yup.object()
    .shape({
      codigo_pes: Yup.number()
        .nullable()
        .test('obrigaResponsavel', 'Campo responsável é obrigatório!', function (value) {
          if (this?.from?.[1]?.value?.etapa_responsabilidade?.codigo_etr === 2 && !value) {
            return false
          }
          return true
        })
    }).nullable(),
  descri_eta: Yup.string()
    .nullable(false)
    .required('Campo desrição é obrigatório!')
})

export const EtapaUsoProcessoUsoValidator = Yup.object().shape({
  etapa_responsabilidade: Yup.object()
    .shape({
      codigo_etr: Yup.number()
        .nullable()
        .required('Campo responsável da etapa é obrigatório!')
    })
    .nullable(),
  responsavel: Yup.object()
    .shape({
      codigo_pes: Yup.number()
        .nullable()
        .test('obrigaResponsavel', 'Campo responsável é obrigatório!', function (value) {
          if (this?.from?.[1]?.value?.etapa_responsabilidade?.codigo_etr === 2 && !value) {
            return false
          }
          return true
        })
    }).nullable(),
  descri_eus: Yup.string()
    .nullable(false)
    .required('Campo desrição é obrigatório!')
})
