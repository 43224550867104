
export const getEtapaDTO = (data = {}) => {
  return {
    ...data,
    responsavel: data.responsavel || null,
    etapa_responsabilidade: data.etapa_responsabilidade || {},
    pendencias: data.pendencias || []
  }
}

export const postEtapaDTO = (data = {}) => {
  return {
    ...data,
    nomraz_pes: data?.responsavel?.nomraz_pes || data?.etapa_responsabilidade?.descri_etr || '',
    ordem_eta: 0,
    pendencias: data.pendencias || []
  }
}

export const postEtapaUsoDTO = (data = {}) => {
  return {
    ...data,
    descri_etr: data?.etapa_responsabilidade?.codigo_etr === 2 ? data?.responsavel?.nomraz_pes : data?.etapa_responsabilidade?.descri_etr || ''
  }
}
