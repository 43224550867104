import React, { useEffect, useState } from 'react'
import SelectInput from '../../../../../../components/inputs/SelectInput'
import CommonHelper from '../../../../../../helpers/CommonHelper'
import { showErrorMessage } from '../../../../../../components/utils/Message'
import Modal from '../../../../../../components/utils/Modal'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'
import { classNames } from 'primereact/utils'
import ArquivoReferenteService from '../../../../../../services/processo/ArquivoReferenteService'
import TextInput from '../../../../../../components/inputs/TextInput'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import EtapaTipoPendenciaUsoService from '../../../../../../services/processo/EtapaTipoPendenciaUsoService'

const PendenciasUsoModal = ({
  visible,
  onHide,
  pendenciaForm,
  pendenciaSelecionada,
  form,
  edicaoHabilitada,
  setPendencias,
  processoEmAndamento
}) => {
  // * busca index da pendencia selecionada na listagem
  async function getIndexPendenciaSelecionada () {
    const pendencias = form.values.pendencias
    const indexPendencia = pendencias.indexOf(pendenciaSelecionada)
    return indexPendencia
  }

  // * Exclui pendencia da listagem
  async function excluirPendencia () {
    const pendencias = form.values.pendencias
    await getIndexPendenciaSelecionada().then(result => {
      pendencias.splice(result, 1)
    })
    setPendencias(pendencias)
    const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
    const finalizadas = parseInt(finalizadasStr, 10)
    const total = parseInt(totalStr, 10)

    const novoTotal = total - 1
    const novoFinalizado = pendenciaForm.values?.descri_concluido === 'Não' ? finalizadas : finalizadas - 1

    await form.setFieldValue('pendencias_finalizadas', `${novoFinalizado} / ${novoTotal}`)

    await form.submitForm()

    onHide()
  }

  // * Submita para add Pendencia na listagem
  async function adicionarPendencias () {
    await pendenciaForm.submitForm()
  }

  // * Constantes
  const [arquivoReferente, setArquivoReferente] = useState([])

  // * Realiza busca no primeiro render
  useEffect(async () => {
    await getArquivoReferente()
  }, [])

  // * Gets para Selects Inpúts
  const getArquivoReferente = async () => {
    try {
      await ArquivoReferenteService.getAll().then(ArquivoReferente => setArquivoReferente(ArquivoReferente))
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao buscar as origens de anexo')
    }
  }

  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(pendenciaForm)

  useEffect(() => {
    if (!!pendenciaSelecionada || !visible) return
    if (!pendenciaForm.values?.origem_anexo?.codigo_tet && arquivoReferente.length) {
      pendenciaForm.setFieldValue('origem_anexo', arquivoReferente[0])
      pendenciaForm.validateField('origem_anexo.codigo_are')
    }
  }, [visible, arquivoReferente, pendenciaSelecionada])

  async function ignorarOrBaixarPendencia (ignorarOrBaixar) {
    if (!processoEmAndamento || !pendenciaSelecionada?.codigo_tpu) return

    try {
      const pendencias = form.values.pendencias

      const indexPendencia = getIndexPendenciaSelecionada()

      const pendenciaSaved = await EtapaTipoPendenciaUsoService[ignorarOrBaixar](pendenciaSelecionada.codigo_tpu)

      pendencias.splice(indexPendencia, 1, pendenciaSaved)

      setPendencias(pendencias)

      const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
      const finalizadas = parseInt(finalizadasStr, 10)
      const total = parseInt(totalStr, 10)
      let novasFinalizadas = finalizadas
      novasFinalizadas = pendenciaForm?.values?.descri_concluido === 'Não' ? novasFinalizadas + 1 : novasFinalizadas

      await form.setFieldValue('pendencias_finalizadas', `${novasFinalizadas} / ${total}`)

      await form.submitForm()

      onHide()
    } catch (e) {
      showErrorMessage(e.message || `Ocorreu um erro inesperado ao ${ignorarOrBaixar} a pendência`)
    }
  }

  async function desfazerConclusaoPendencia () {
    if (!processoEmAndamento || !pendenciaSelecionada?.codigo_tpu) return

    try {
      const pendencias = form.values.pendencias

      const indexPendencia = getIndexPendenciaSelecionada()

      const pendenciaSaved = await EtapaTipoPendenciaUsoService.desfazerConclusao(pendenciaSelecionada.codigo_tpu)

      pendencias.splice(indexPendencia, 1, pendenciaSaved)

      setPendencias(pendencias)

      const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
      const finalizadas = parseInt(finalizadasStr, 10)
      const total = parseInt(totalStr, 10)
      let novasFinalizadas = finalizadas
      novasFinalizadas = pendenciaForm?.values?.descri_concluido !== 'Não' ? novasFinalizadas - 1 : novasFinalizadas

      await form.setFieldValue('pendencias_finalizadas', `${novasFinalizadas} / ${total}`)

      await form.submitForm()

      onHide()
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao desfazer conclusão da pendência')
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={'Pendência'}
        btnSalvarLabel={pendenciaSelecionada ? 'Atualizar pendência' : 'Adicionar pendência'}
        icon={pendenciaSelecionada ? '' : 'pi pi-plus'}
        width={75}
        btnSalvar={edicaoHabilitada ? adicionarPendencias : undefined}
        btnExcluir={
          (pendenciaSelecionada && edicaoHabilitada)
            ? excluirPendencia
            : undefined}
        btnExcluirLabel={pendenciaForm.values?.codeus_tpu ? 'Remover pendência' : null}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <RequiredLabel label="Descrição" valid={isFormFieldValid('descri_tpu')} />
            <TextInput
              placeholder="Descrição"
              name="descri_tpu"
              className={classNames({ 'p-invalid': isFormFieldValid('descri_tpu') }, 'inputfield w-full')}
              value={pendenciaForm.values.descri_tpu}
              onChange={pendenciaForm.handleChange}
              disabled={!edicaoHabilitada}
              onBlur={() => pendenciaForm.setFieldTouched('descri_tpu')}
            />
            <FieldErrorMessage message={getFormErrorMessage('descri_tpu')} />
          </div>

          <div className="field col-12 md:col-6">
            <RequiredLabel label="Origem do anexo" valid={isFormFieldValid('origem_anexo.codigo_are')} />
            <SelectInput
              value={ pendenciaForm.values.origem_anexo.codigo_are && pendenciaForm.values.origem_anexo }
              options={arquivoReferente}
              onChange={(e) => {
                pendenciaForm.setFieldValue('origem_anexo', e.target.value || {})
                pendenciaForm.validateField('origem_anexo.codigo_are')
              }}
              form={pendenciaForm}
              name="origem_anexo"
              placeholder="Selecione a origem do anexo"
              filter={CommonHelper.isDesktop()}
              optionLabel="descri_are"
              dataKey="codigo_are"
              className={classNames({ 'p-invalid': isFormFieldValid('origem_anexo.codigo_are') }, 'inputfield w-full')}
              noFloatLabel
              disabled={!edicaoHabilitada}
              onBlur={() => pendenciaForm.setFieldTouched('origem_anexo.codigo_are')}
            />
            <FieldErrorMessage message={getFormErrorMessage('origem_anexo.codigo_are')} />
          </div>
          <div className="checkbox-container col-6 md:col-2 flex justify-content-center">
            <Checkbox
              value={pendenciaForm.values.exiarq_tpu}
              onChange={(e) => {
                pendenciaForm.setFieldValue('exiarq_tpu', !e.target.value)
              }}
              checked={pendenciaForm.values.exiarq_tpu}
              name="exiarq_tpu"
              disabled={!edicaoHabilitada}
            />
            <label htmlFor="exiarq_tpu" className="p-checkbox-label mb-0 pl-2">Exige anexo?</label>
          </div>
          <div className="checkbox-container col-6 md:col-2 flex justify-content-center">
            <Checkbox
              value={pendenciaForm.values.perign_tpu}
              onChange={(e) => {
                pendenciaForm.setFieldValue('perign_tpu', !e.target.value)
              }}
              checked={pendenciaForm.values.perign_tpu}
              name="perign_tpu"
              disabled={!edicaoHabilitada}
            />
            <label htmlFor="perign_tpu" className="p-checkbox-label mb-0 pl-2">Permite ignorar</label>
          </div>
          {!!processoEmAndamento && (
            <div className="field col-12 flex mt-2 justify-content-around md:hidden flex-wrap">
              <Button
                label="Baixar pendência"
                className="mt-2"
                onClick={() => ignorarOrBaixarPendencia('baixar')}
                disabled={!processoEmAndamento}
              />
              <Button
                label="Ignorar pendência"
                className="mt-2"
                onClick={() => ignorarOrBaixarPendencia('ignorar')}
                disabled={!processoEmAndamento}
              />
              <Button
                label="Marcar como pendente"
                className="mt-2"
                onClick={() => desfazerConclusaoPendencia()}
                disabled={!processoEmAndamento}
              />
            </div>
          )}
        </div>
      </Modal>
    </>

  )
}

export default PendenciasUsoModal
