import ServiceBase from '../../base/ServiceBase'

export default class PessoaService {
  async get (id) {
    return await ServiceBase.getBase(`pessoas/${id}`)
  }

  async getAll (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas?page=${page}&perPage=${perPage}`)
  }

  static async getPessoa (id) {
    return await ServiceBase.getBase(`pessoas/${id}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`pessoas?${query}&page=${page}&perPage=${perPage}`)
  }

  async delete (data, id) {
    return await ServiceBase.deleteBase(`pessoas/${id}`, data)
  }

  static async filterAutocomplete (query) {
    if (!!Number(query)) {
      const response = await ServiceBase.getBase('pessoas?codigo_pes=' + query, false)
      return response.data
    }
    const response = await ServiceBase.getBase('pessoas?name=' + query, false)
    return response.data
  }

  async filterAutocomplete (query) {
    if (!!Number(query)) {
      const response = await ServiceBase.getBase('pessoas?codigo_pes=' + query, false)
      return response.data
    }
    const response = await ServiceBase.getBase('pessoas?name=' + query, false)
    return response.data
  }

  static async filterAutocompleteConveniado (codigoCliente, nomeConveniado) {
    const response = await ServiceBase.getBase('pessoas?name=' + nomeConveniado + '&codpejConveniado=' + codigoCliente, false)
    return response.data
  }

  static async getUrlComoChegar (codigo_pes) {
    return (await ServiceBase.getBase(`pessoas/comochegar/${codigo_pes}`)).url
  }

  static async getItermediador () {
    return await ServiceBase.getBase('intermediador')
  }

  static async getAprovadoresReq (codigoPer, codigoEmp) {
    return await ServiceBase.getBase(`pessoas/${codigoPer}/${codigoEmp}`)
  }

  static async getClienteReserva (codigo_vei) {
    if (codigo_vei) {
      return await ServiceBase.getBase(`pessoas/clienteReserva/${codigo_vei}`)
    } else {
      return null
    }
  }
}
