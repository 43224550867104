import React, { useState } from 'react'
import Page from '../../../../components/layout/Page'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import { createColumns, getColunasHabilitadas } from '../../../../helpers/tableConfigs'
import useList from '../../../../hook/useList'
import { Button } from 'primereact/button'
import { getAcompanhamentoDTO } from '../../../../dtos/processo/AcompanhamentoDTO'
import AcompanhamentoService from '../../../../services/processo/AcompanhamentoService'
import CommonHelper from '../../../../helpers/CommonHelper'
import VendedoresService from '../../../../services/cadastro/pessoa/VendedoresService'
import { showErrorMessage, showWarnMessage } from '../../../../components/utils/Message'
import ExpandedFiltersTemplateForAcompanhamento from './ExpandedFilters'
import TablePreferences from '../../../../components/utils/TablePreferences'
import PreferencesButton from '../../../../components/utils/PreferencesButton'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'

const AcompanhamentoProcessoList = () => {
  // * Cria as colunas que serão utilizadas no dataList e mo HOOK
  const columns = createColumns([
    { field: 'codigo_pru', header: 'Código', width: '5%' },
    { field: 'nome_empresa', header: 'Empresa', width: '11%' },
    { field: 'descri_prc', header: 'Processo', width: '11%' },
    { field: 'negprc_pru', header: 'Negociação', width: '11%' },
    { field: 'nome_cliente', header: 'Cliente', width: '11%' },
    { field: 'nome_atendente', header: 'Atendente', width: '11%' },
    {
      field: 'pendencias',
      header: 'Pendências',
      width: '11%',
      customBody: function customBody (data) {
        const totalPendencias = data?.pendencias?.total || 0
        const pendenciasFinalizadas = data?.pendencias?.finalizadas || 0
        const porcentagem = totalPendencias === 0 ? 0 : (pendenciasFinalizadas / totalPendencias) * 100

        return (
          <div>
            <div style={{ marginBottom: '5px' }}>
              {totalPendencias === 0
                ? 'Sem pendências'
                : (pendenciasFinalizadas - totalPendencias) === 0
                  ? 'Concluído'
                  : `Pendências: ${pendenciasFinalizadas} / ${totalPendencias}`
              }
            </div>
            <div
              style={{
                height: '8px',
                width: '100%',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px'
              }}
              className="hidden md:block"
            >
              <div
                style={{
                  height: '100%',
                  width: `${porcentagem}%`,
                  backgroundColor: '#4caf50',
                  borderRadius: '4px',
                  transition: 'width 0.5s ease'
                }}
              />
            </div>
          </div>
        )
      }
    },
    { field: 'descri_situacao', header: 'Situação', width: '11%', enabled: false },
    { field: 'observ_pru', header: 'Observação', width: '11%' }
  ])

  // * Utilziação do HOOK "useList" no Function Component para substituir o "Extends List" do Class Component
  const {
    state,
    setState,
    onPageChange,
    onSelect,
    onFilter,
    handleChangeFilter,
    onView,
    onNew
  } = useList(
    getAcompanhamentoDTO,
    AcompanhamentoService,
    'novo-processo',
    'codigo_pru',
    undefined,
    undefined,
    {
      selectedOptionsEmpresas: null,
      empresas: [],
      selectedOptionsProcessos: null,
      processos: [],
      atendente: null,
      cliente: null,
      descri_negociacao: '',
      descri_veiculo: '',
      descri_obs: '',
      selectedOptionsSituacao: null,
      situacoes: [1],
      processos_por_usuario_logado: true,
      startFilters: {
        situacoes: [1],
        processos_por_usuario_logado: true
      },
      columns
    }
  )

  // * Constantes
  const [sugestaoAtendente, setSugestaoAtendente] = useState([])
  const [sugestaoCliente, setSugestaoCliente] = useState([])
  const [visiblePreferencesModal, setVisiblePreferencesModal] = useState(false)

  // * Funções para o SearchInput de atendente
  const sugerirAtendente = async ({ query }) => {
    try {
      // * Utiliza VendedorService que é a mesma coisa
      const atendenteService = new VendedoresService()
      const atendente = await atendenteService.filterAutoComplete(`nomraz_pes=${query}`)

      if (atendente.length <= 0) {
        showWarnMessage('Atendente não encontrado!')
      }

      setSugestaoAtendente(atendente)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um atendente!')
    }
  }

  // * Funções para o SearchInput de cliente
  const sugerirCliente = async ({ query }) => {
    try {
      const pessoaService = new PessoaService()
      const pessoa = await pessoaService.filterAutocomplete(query)

      if (pessoa.length <= 0) {
        showWarnMessage('Cliente não encontrado!')
      }

      setSugestaoCliente(pessoa)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um cliente!')
    }
  }

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Acompanhamento de processo</h4>
        <div className="page-header-buttons">
          <Button className="mr-2" label={CommonHelper.isDesktop() && 'Novo processo'} icon="pi pi-plus" onClick={onNew} />
        </div>
      </div>

      <div className="flex justify-content-end mr-3">
        <PreferencesButton onClick={() => setVisiblePreferencesModal(true)} />
      </div>

      <Container>
        <DataList
          data={state.list}
          selected={state.selected}
          rows={state.rows}
          onSelect={onSelect}
          totalRecords={state.totalRecords}
          first={state.first}
          onPage={onPageChange}
          page={state.page}
          onRowSelect={(e) => onView(e.data)}
          filterPlaceholder="Pesquisar"
          filterName="descricaoFiltro"
          filterOnChange={handleChangeFilter}
          onFilter={onFilter}
          responsive
          showFilter
          startFilterOpened
          customMaxHeight={'unset'}

          // * Expanded Filters
          expandedFiltersTemplate={ExpandedFiltersTemplateForAcompanhamento}
          setState={setState}
          state={state}

          // * Campo Atendente
          sugestaoAtendente={sugestaoAtendente}
          sugerirAtendente={sugerirAtendente}

          // * Campo Atendente
          sugestaoCliente={sugestaoCliente}
          sugerirCliente={sugerirCliente}
        >
          {getColunasHabilitadas(columns, 'acompanhamento-processo')}
        </DataList>
      </Container>

      <TablePreferences
        title="Preferências da tabela de acompanhamento"
        tableName="acompanhamento-processo"
        columns={columns}
        visible={visiblePreferencesModal}
        hide={() => setVisiblePreferencesModal(false)}
      />
    </Page>
  )
}

export default AcompanhamentoProcessoList
