import React, { useState } from 'react'

import { Column } from 'primereact/column'
import AppButton from '../../../../components/layout/AppButton'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import Modal from '../../../../components/utils/Modal'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import PessoaService from '../../../../services/cadastro/pessoa/PessoaService'
import UsuarioPermissaoReqModal from './UsuarioPermissaoReqModal'
import OrcamentoBalcaoService from '../../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import { showSuccessMessage } from '../../../../components/utils/Message'
import ModalMotivoCancelamento from '../../../../components/utils/MotivoCancelamento'
import ModalConfirmUserPass from '../../../../components/utils/ConfirmUserPass'
import PermissaoService from '../../../../services/cadastro/pessoa/PermissaoService'

const RequisicaoLiberacaoPendentesModal = ({
  hide,
  visible,
  listReqPendentes,
  getOrcamentosPendentes,
  getOrcamentosRecusados,
  form
}) => {
  const [expandedRows, setExpandedRows] = useState(null)
  const [requisicaoSelecionada, setRequisicaoSelecionada] = useState([])
  const [visibleUsuarioPermissao, setVisibleUsuarioPermissao] = useState(false)
  const [visibleModalCancelamento, setVisibleModalCancelamento] = useState(false)
  const [visibleModalConfirmUserPass, setVisibleModalConfirmUserPass] = useState(false)
  const [listaAprovadores, setListaAprovadores] = useState([])

  const rowExpansionTemplate = () => {
    return (
      <div className="flex flex-wrap justify-content-between" >
        <div className='flex flex-wrap w-full md:w-auto'>
          <AppButton
            label="Aprovar"
            className="m-2 md:mr-1 w-full md:w-auto"
            onClick={async () => {
              await conferePermissaoParaAprovar()
            }}
          />
          <AppButton
            label="Recusar"
            className="m-2 md:ml-0 w-full md:w-auto"
            onClick={() => {
              setVisibleModalCancelamento(true)
            }}
          />
        </div>

        <AppButton
          label="Ver aprovadores"
          className="m-2 w-full md:w-auto"
          onClick={async () => {
            await getAprovadores()
          }}
        />
      </div>
    )
  }

  async function getAprovadores () {
    try {
      const aprovadores = await PessoaService.getAprovadoresReq(requisicaoSelecionada?.codper_req, requisicaoSelecionada?.codemp_req)
      setListaAprovadores(aprovadores)
      setVisibleUsuarioPermissao(state => !state)
    } catch (error) {
    }
  }

  const conferePermissaoParaAprovar = async () => {
    const temPermissao = await PermissaoService.getByCodigo(requisicaoSelecionada.codper_req)

    if (!temPermissao) {
      setVisibleModalConfirmUserPass(true)
    } else {
      await aprovarOrcamentoPendencia(requisicaoSelecionada)
    }
  }

  async function aprovarOrcamentoPendencia (data) {
    if (!data?.codigo_req) return
    try {
      await OrcamentoBalcaoService.postAprovarPendencia(data?.codigo_req)
      await setVisibleModalConfirmUserPass(false)
      await setExpandedRows(null)
      await setRequisicaoSelecionada([])
      await hide()
      showSuccessMessage('Pendência aprovada com sucesso!')
      await getOrcamentosPendentes()
    } catch (error) {
    }
  }

  async function recusarOrcamentoPendencia (data, motivo) {
    if (!data?.codigo_req) return
    try {
      await OrcamentoBalcaoService.postRecusarPendencia(data?.codigo_req, { motivo })
      await setVisibleModalCancelamento(false)
      await setExpandedRows(null)
      await setRequisicaoSelecionada([])
      await hide()
      showSuccessMessage('Pendência recusada com sucesso!')
      await getOrcamentosPendentes()
      await getOrcamentosRecusados()
    } catch (error) {
    }
  }

  const handleRowExpansion = (e) => {
    if (expandedRows && expandedRows[0] === e) {
      setExpandedRows(null)
    } else {
      setExpandedRows([e])
    }
  }

  return (
    <>
      <Modal
        header="Situação das requisições"
        width="70"
        visible={visible}
        modal={true}
        onHide={hide}
      >
        <Container>
          <ModalMotivoCancelamento
            visible={visibleModalCancelamento}
            onConfirm={(motivo) => recusarOrcamentoPendencia(requisicaoSelecionada, motivo)}
            onCancel={() => setVisibleModalCancelamento(false)}
          />
          <ModalConfirmUserPass
            visible={visibleModalConfirmUserPass}
            onConfirm={() => aprovarOrcamentoPendencia(requisicaoSelecionada)}
            onCancel={() => setVisibleModalConfirmUserPass(false)}
            buscarPermissao={requisicaoSelecionada?.codper_req}
            codigoEmpresa={form?.values?.codemp_opv}
          />
          <DataList
            data={listReqPendentes}
            onSelect={(e) => { setRequisicaoSelecionada(e) }}
            totalRecords={0}
            first={0}
            rows={0}
            page={1}
            hidePaginator={true}
            rowExpansionTemplate={rowExpansionTemplate}
            showFilter={false}
            expandedRows={expandedRows}
            onRowClick={(e) => handleRowExpansion(e.data)}

          >
            <Column
              header="ORIGEM"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="ORIGEM"
                  value={data?.origem_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="origem_req"
            />
            <Column
              header="REQUISIÇÃO"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="REQUISIÇÃO"
                  value={data?.motivo_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="motivo_req"
            />
            <Column
              header="CLIENTE"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="CLIENTE"
                  value={data?.nomcli_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="nomcli_req"
            />
            <Column
              header="EMPRESA"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="EMPRESA"
                  value={data?.empnom_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="empnom_req"
            />
            <Column
              header="USUÁRIO"
              body={(data) => {
                return <ResponsiveColumn
                  className="pl-2"
                  column="USUÁRIO"
                  value={data?.reqnom_req}
                />
              }}
              headerClassName="HeaderDataList width-40"
              sortable={true}
              field="reqnom_req"
            />
          </DataList>
        </Container>
      </Modal>
      <UsuarioPermissaoReqModal
        visible={visibleUsuarioPermissao}
        hide={() => {
          setVisibleUsuarioPermissao(false)
          setListaAprovadores([])
        }}
        listaAprovadores={listaAprovadores}
      />
    </>

  )
}

export default RequisicaoLiberacaoPendentesModal
