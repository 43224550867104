import React, { useEffect, useState } from 'react'
import Modal from '../Modal'
import AppButton from '../../layout/AppButton'
import TextInput from '../../inputs/TextInput'
import PermissaoService from '../../../services/cadastro/pessoa/PermissaoService'
import { showErrorMessage } from '../Message'

const ModalConfirmUserPass = ({
  visible,
  onCancel,
  onConfirm,
  buscarPermissao,
  codigoEmpresa
}) => {
  const [descricaoPermissao, setDescricaoPermissao] = useState('')

  const [emailConfirmUserPass, setEmailConfirmUserPass] = useState('')
  const setUseStateEmailConfirmUserPass = async (valor) => await setEmailConfirmUserPass(valor)

  const [passConfirmUserPass, setPassConfirmUserPass] = useState('')
  const setUseStatePassConfirmUserPass = async (valor) => await setPassConfirmUserPass(valor)

  const customOnCancel = () => {
    !!onCancel && onCancel()
  }

  const customOnConfirm = async () => {
    try {
      const temPermissao = await PermissaoService.getByCodigoAndUserPass(
        buscarPermissao,
        codigoEmpresa,
        {
          emailConfirmUserPass,
          passConfirmUserPass
        }
      )

      if (!temPermissao) {
        showErrorMessage(`Usuário especificado não contém a permissão ${buscarPermissao}. Tente novamente!`)
        return
      }

      !!onConfirm && onConfirm()
    } catch (error) {
      showErrorMessage(error.message ?? `Erro ao solicitar permissão ${buscarPermissao}. Tente novamente!`)
    }
  }

  const getPermissaoDescricao = async () => {
    const { data: descriPermissao } = await PermissaoService.filter(`codigo_per=${buscarPermissao}`)

    setDescricaoPermissao(descriPermissao[0]?.descri_per || '')

    return descriPermissao
  }
  useEffect(() => {
    if (!visible || !buscarPermissao) {
      setDescricaoPermissao('')
      return
    }
    getPermissaoDescricao()
  }, [visible, buscarPermissao])

  return (
    <Modal
      header={`Permissão ${buscarPermissao}`}
      visible={visible}
      width="30"
      modal={true}
      onHide={onCancel}
    >
      Para continuar, informe um usuário que possua a permissão <b>{buscarPermissao} - {descricaoPermissao}</b>

      <div className="flex w-full mt-2 mb-3">
        <div className="flex flex-column w-full">
          <div className="col-12">
            <TextInput
              value={emailConfirmUserPass}
              label={'Usuário'}
              onChange={e => setUseStateEmailConfirmUserPass(e.target.value)}
              className="inputfield w-full"
            />
          </div>
          <div className="col-12">
            <TextInput
              value={passConfirmUserPass}
              label={'Senha'}
              type='password'
              onChange={e => setUseStatePassConfirmUserPass(e.target.value)}
              className="inputfield w-full"
            />
          </div>
        </div>
      </div>

      <div className="p-dialog-buttons">
        <AppButton
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-danger mr-2"
          onClick={customOnCancel}
        />
        <AppButton
          label="Permitir"
          icon="pi pi-check"
          onClick={customOnConfirm}
        />
      </div>
    </Modal>
  )
}

export default ModalConfirmUserPass
