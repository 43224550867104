import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { classNames } from 'primereact/utils'
import React, { useState } from 'react'
import Container from '../../../../components/layout/Container'
import DataList from '../../../../components/layout/DataList'
import FieldErrorMessage from '../../../../components/utils/FieldErrorMessage'
import { showErrorMessage } from '../../../../components/utils/Message'
import RequiredLabel from '../../../../components/utils/RequiredLabel'
import ResponsiveColumn from '../../../../components/utils/ResponsiveColumn'
import { formatDate, formatDateToAmerican, formatToCurrency } from '../../../../helpers/formaters'
import { useValidateInput } from '../../../../helpers/useValidateInput'
import OrcamentoBalcaoService from '../../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import CondicaoPagamentoModal from '../../../financeiro/CondicaoPagamentoModal'

function DadosPagamentoOpv ({
  form,
  edicaoHabilitada,
  totalOrcamento,
  camposObrigatorios,
  formasCobranca
}) {
  const [visibleCondicaoPagamentoModal, setVisibleCondicaoPagamentoModal] = useState(false)
  const [totalRecords, setTotalRecords] = useState(0)
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  async function configurarTituloOpv (condicaoPagSelecionado) {
    try {
      condicaoPagSelecionado.valorFinal = totalOrcamento
      const tituloOpvRes = await OrcamentoBalcaoService.configurarTituloOpv(form.values.codigo_opv, condicaoPagSelecionado)

      form.setFieldValue('tituloOpv', tituloOpvRes)
      setTotalRecords(tituloOpvRes.Length)
      const { totalValacr, valorTotalOpv } = tituloOpvRes.reduce(
        (acumulador, titulo) => {
          acumulador.totalValacr += titulo.valacr_top
          acumulador.valorTotalOpv += titulo.valor_top
          return acumulador
        },
        { totalValacr: 0, valorTotalOpv: 0 }
      )
      form.setFieldValue('codcop_opv', condicaoPagSelecionado.codigo_cop)
      form.setFieldValue('condicaoPagamento', condicaoPagSelecionado)
      form.setFieldValue('acrcpg_opv', totalValacr)
      form.setFieldValue('totcop_opv', valorTotalOpv)
      form.setFieldValue('priven_opv', formatDateToAmerican(condicaoPagSelecionado.dataPrimeiroVencimento
      ))
      form.setFieldValue('valent_opv', condicaoPagSelecionado.valorEntrada)
      form.setFieldTouched('condicaoPagamento.codigo_cop')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao gerar as parcelas!')
    }
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12 md:col-5  mx-auto text-center">
        <RequiredLabel label="Condição de pagamento" valid={isFormFieldValid('condicaoPagamento.codigo_cop')} />
        <div className="p-inputgroup">
          <AutoComplete
            onBlur={() => form.setFieldTouched('condicaoPagamento.codigo_cop')}
            name="codigo_cop"
            field="codcop_opv"
            value={form.values.condicaoPagamento && form.values.condicaoPagamento.descri_cop}
            minLength={3}
            form={form}
            isFormFieldValid={isFormFieldValid('condicaoPagamento.codigo_cop')}
            camposObrigatorios={camposObrigatorios}
            placeholder={'Descrição da condição de pagamento'}
            disabled={true}
            className={classNames({ 'p-invalid': isFormFieldValid('condicaoPagamento.codigo_cop') }, 'inputfield w-full')}

          />
          <Button
            icon="pi pi-search"
            onClick={() => {
              setVisibleCondicaoPagamentoModal(true)
              form.setFieldTouched('condicaoPagamento.codigo_cop')
            }}
            disabled={!edicaoHabilitada}
          />
        </div>
        <FieldErrorMessage message={getFormErrorMessage('condicaoPagamento.codigo_cop')} />
      </div>
      <Container>
        <DataList
          data={form.values.tituloOpv}
          rows={totalRecords}
          hidePaginator={true}
          totalRecords={totalRecords}
          first={1}
          page={1}
          showFilter={false}
          responsive={true}
        >
          <Column
            header="Ordem"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Ordem"
                value={data.ordem_top}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="ordem_top"
          />
          <Column
            header="Vencimento"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Vencimento"
                value={formatDate(data.datven_top)}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="datven_top"
          />

          <Column
            header="Valor"
            body={(data) => (
              <ResponsiveColumn
                className="pl-2"
                column="Valor"
                value={formatToCurrency(data.valor_top)}
              />
            )}
            headerClassName="HeaderDataList"
            sortable={true}
            field="valor_top"
          />
          <Column
            header="Forma de pagamento"
            body={(data) => {
              const foc = formasCobranca?.find(
                (forma) => forma.codigo_foc === data.codfoc_top
              )
              return <ResponsiveColumn
                className="pl-2"
                column="Forma de pagamento"
                value={foc?.descri_foc}
              />
            }}
            headerClassName="HeaderDataList width-40"
            sortable={true}
            field="codcop_top"
          />
        </DataList>

        <div className='mt-4 mb-6 text-center font-bold'>{formatToCurrency(form.values.totcop_opv)}</div>
      </Container>

      <CondicaoPagamentoModal
        visible={visibleCondicaoPagamentoModal}
        onHide={() => { setVisibleCondicaoPagamentoModal(false) }}
        edicaoHabilitada={edicaoHabilitada}
        totalOrcamento={totalOrcamento}
        configurarTitulos={configurarTituloOpv}
      ></CondicaoPagamentoModal>

    </div>
  )
}

export default DadosPagamentoOpv
