/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { baseForm } from '../../../../../../components/utils/BaseForm'
import { getEtapaDTO, postEtapaUsoDTO } from '../../../../../../dtos/processo/EtapaDTO'
import DataListForm from '../../../../../../components/layout/DataListForm/DataListForm'
import PendenciasEtapaProcessoUsoModal from '..'
import { EtapaUsoProcessoUsoValidator } from '../../../../ModeloProcesso/Form/etapasEpendencias/validator'
import { showWarnMessage } from '../../../../../../components/utils/Message'

function DadosEtapasProcessoUso ({
  form,
  edicaoHabilitada,
  camposObrigatorios
}) {
  const [visibleEtapaModal, setVisibleEtapaModal] = useState(false)
  const [etapaSelecionada, setEtapaSelecionada] = useState(null)
  const [responsavel, setResponsavel] = useState(null)

  async function insertOrUpdateEtapa () {
    if (!responsavel?.codigo_pes) return showWarnMessage('Campo responsável é obrigatório')

    if (etapaSelecionada) {
      await editarEtapa()
    }

    etapaForm.resetForm()
    hideEtapaModal()
  }

  async function editarEtapa () {
    if (etapaForm.values?.descri_etr === 'Atendente') {
      await form.setFieldValue('atendente', {
        codigo_ven: responsavel?.codigo_pes,
        nomraz_pes: responsavel?.nomraz_pes
      })
    } else {
      await etapaForm.setFieldValue('responsavel', responsavel)
    }

    const etapas = form.values?.etapas

    const indexEtapa = etapas.indexOf(etapaSelecionada)

    const formatedValues = postEtapaUsoDTO(etapaForm.values?.descri_etr !== 'Atendente' ? { ...etapaForm.values, responsavel } : etapaForm.values)

    etapas.splice(indexEtapa, 1, formatedValues)

    await form.setFieldValue('etapas', etapas)
  }

  const etapaForm = baseForm({
    initialValues: getEtapaDTO(),
    validationSchema: EtapaUsoProcessoUsoValidator,
    onSubmit: insertOrUpdateEtapa
  })

  useEffect(async () => {
    if (etapaSelecionada) {
      etapaForm.setValues(getEtapaDTO(etapaSelecionada))
    } else {
      etapaForm.setValues(getEtapaDTO())
    }
  }, [etapaSelecionada])

  useEffect(() => {
    if (!etapaSelecionada) setResponsavel(null)
    setResponsavel(
      etapaForm?.values?.descri_etr === 'Atendente'
        ? {
          codigo_pes: form.values?.atendente?.codigo_ven,
          nomraz_pes: form.values?.atendente?.nomraz_pes
        }
        : etapaForm?.values?.responsavel
    )
  }, [etapaForm?.values])

  const columns = [
    {
      field: 'descri_eus',
      header: 'Etapa',
      className: 'text-left pl-4',
      format: (valor) => valor
        ? <span
          style={ { marginLeft: '0.25rem' } }
        >
          {valor}
        </span>
        : ''
    },
    {
      field: 'descri_etr',
      header: 'Responsável',
      className: 'text-left pl-4',
      format: (valor) => valor
        ? <span
          style={ { marginLeft: '0.25rem' } }
        >
          {valor}
        </span>
        : ''

    },
    {
      field: 'pendencias_finalizadas',
      header: 'Pendências',
      className: 'text-left pl-4',
      format: (valor) => valor
        ? <span
          style={ { marginLeft: '0.25rem' } }
        >
          {valor}
        </span>
        : ''

    }
  ]

  function hideEtapaModal () {
    setEtapaSelecionada(null)
    setVisibleEtapaModal(false)
    etapaForm.resetForm()
  }

  return (
    <div className="formgrid grid fluid">
      <div className="field col-12">
        <DataListForm
          value={form.values?.etapas}
          columns={columns}
          emptyMessage="Nenhuma etapa cadastrada."
          onRowSelect={async (e) => {
            await setEtapaSelecionada(e.data)
            setVisibleEtapaModal(true)
          }}
        />
      </div>
      {!!visibleEtapaModal && <PendenciasEtapaProcessoUsoModal
        visible={visibleEtapaModal}
        onHide={() => hideEtapaModal()}
        camposObrigatorios={camposObrigatorios}
        etapaForm={etapaForm}
        etapaSelecionada={etapaSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        form={form}
        setEtapas={(etapas) => {
          form.setFieldValue('etapas', etapas)
        }}
        responsavel={responsavel}
        setResponsavel={setResponsavel}
      />}
    </div>
  )
}

export default DadosEtapasProcessoUso
