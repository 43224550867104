import React, { useEffect, useState } from 'react'
import AutoCompleteInput from '../../../../../components/inputs/AutoCompleteInput'
import { PessoaFisica } from '../../../../cadastro/pessoa/fisica/PessoaFisica'
import { PessoaJuridica } from '../../../../cadastro/pessoa/juridica/PessoaJuridica'
import { formatDocument } from '../../../../../helpers/formaters'
import PessoaService from '../../../../../services/cadastro/pessoa/PessoaService'
import { PessoaModel } from '../../../../../models/cadastro/pessoa/PessoaModel'
import PessoaAutocompleteTemplate from '../../../../../components/inputs/options/PessoaAutocompleteTemplate'
import Modal from '../../../../../components/utils/Modal'
import DadosPendenciasUso from './Pendencias/ListWithModal'
import PermissaoService from '../../../../../services/cadastro/pessoa/PermissaoService'
import VendedoresService from '../../../../../services/cadastro/pessoa/VendedoresService'
import { VendedorModel } from '../../../../../models/cadastro/pessoa/VendedorModel'
import RequiredLabel from '../../../../../components/utils/RequiredLabel'
import FieldErrorMessage from '../../../../../components/utils/FieldErrorMessage'
import { useValidateInput } from '../../../../../helpers/useValidateInput'
import { classNames } from 'primereact/utils'
import { showErrorMessage, showWarnMessage } from '../../../../../components/utils/Message'

const PendenciasEtapaProcessoUsoModal = ({
  visible,
  onHide,
  etapaForm,
  etapaSelecionada,
  form,
  edicaoHabilitada,
  camposObrigatorios,
  responsavel,
  setResponsavel
}) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(etapaForm)

  // * Submita para add Etapa na listagem
  async function adicionarEtapas () {
    await etapaForm.submitForm()
  }

  // * Constantes State
  const [sugestaoResponsavel, setSugestaoResponsavel] = useState([])
  const [permiteAlterarResponsavel, setPermiteAlterarResponsavel] = useState(false)

  useEffect(() => {
    const fetchPermissao = async () => {
      if (edicaoHabilitada && visible) {
        setPermiteAlterarResponsavel(await PermissaoService.getByCodigo(244))
      } else {
        setPermiteAlterarResponsavel(false)
      }
    }
    fetchPermissao()
  }, [edicaoHabilitada, visible])

  // * Functions para Autocomplete Responsável
  async function onSelectResponsavel (e) {
    setResponsavel(e.value)
  }
  async function handleChangeResponsavel (event) {
    setResponsavel({ nomraz_pes: event.target.value })
  }
  async function sugerirResponsavel ({ query }) {
    try {
      const responsaveis = await PessoaService.filterAutocomplete(query)

      if (responsaveis.length <= 0) {
        showWarnMessage('Responsável não encontrado!')
      }
      setSugestaoResponsavel(responsaveis)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao sugerir um responsável!')
    }
  }

  const atendenteOrPessoaProps = etapaForm.values?.descri_etr === 'Atendente'
    ? {
      service: VendedoresService,
      model: VendedorModel,
      columns: [
        { campo: 'codigo_ven', nome: 'Código' },
        { campo: 'nomraz_pes', nome: 'Nome' }
      ]
    } : {
      service: PessoaService,
      model: PessoaModel,
      columns: [
        { campo: 'codigo_pes', nome: 'Código' },
        { campo: 'nomraz_pes', nome: 'Nome' },
        { campo: 'cgccpf_pes', nome: 'CPF/CNPJ', format: formatDocument }
      ]
    }

  return (
    <>
      <Modal
        visible={visible}
        onHide={onHide}
        header={`${etapaForm.values?.descri_eus}`}
        icon={etapaSelecionada ? '' : 'pi pi-plus'}
        width={75}
        btnSalvarLabel={etapaSelecionada ? 'Atualizar etapa' : 'Adicionar etapa'}
        btnSalvar={(edicaoHabilitada) ? adicionarEtapas : undefined}
        btnExcluir={undefined}
        btnExcluirLabel={null}
      >
        <div className="formgrid grid">

          <div className="field col-12">
            {etapaForm.values?.descri_etr !== 'Atendente' && <RequiredLabel label="Responsavel" valid={isFormFieldValid('responsavel.codigo_pes')} />}
            <AutoCompleteInput
              // * AutoComplete Props Padrões
              label={etapaForm.values?.descri_etr === 'Atendente' ? 'Responsável' : ''}
              name="responsavel.codigo_pes"
              field="nomraz_pes"
              placeholder="Nome do responsável"
              disabled={!(permiteAlterarResponsavel && edicaoHabilitada)}
              camposObrigatorios={camposObrigatorios}
              className={classNames({ 'p-invalid': isFormFieldValid('responsavel.codigo_pes') }, 'inputfield w-full')}
              onBlur={() => etapaForm.setFieldTouched('responsavel.codigo_pes')}

              // * Template pro campo valor
              itemTemplate={PessoaAutocompleteTemplate}
              value={responsavel && responsavel?.nomraz_pes}

              // * Controle do texto e sugestões
              onSelect={async (e) => await onSelectResponsavel(e)}
              minLength={1}
              suggestions={sugestaoResponsavel}
              completeMethod={sugerirResponsavel}
              onChange={handleChangeResponsavel}

              // * RegisterModal
              form={etapaForm}
              selected={responsavel}
              ComponenteCadastro={[
                {
                  label: 'Pessoa física',
                  component: PessoaFisica,
                  header: responsavel?.codigo_pes ? 'Pessoa Física - ' + responsavel?.codigo_pes : 'Cadastro de pessoa física'
                },
                {
                  label: 'Pessoa jurídica',
                  component: PessoaJuridica,
                  header: responsavel?.codigo_pes ? 'Pessoa jurídica - ' + responsavel?.codigo_pes : 'Cadastro de pessoa jurídica'
                }
              ]}
              componenteCadastroProps={{ removerBotaoExcluir: true }}

              // * FiltroModal
              filtrotitle="Pesquisa de responsável"
              semGetInicial
              {...atendenteOrPessoaProps}

              // * RegisterModal && FiltroModal
              primarykeyname="codigo_pes"
            />
            {etapaForm.values?.descri_etr !== 'Atendente' && <FieldErrorMessage message={getFormErrorMessage('responsavel.codigo_pes')} />}

          </div>

          <div className="field col-12">
            <DadosPendenciasUso
              form={etapaForm}
              processoForm={form}
              edicaoHabilitada={edicaoHabilitada}
            />
          </div>
        </div>
      </Modal>
    </>

  )
}

export default PendenciasEtapaProcessoUsoModal
