/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { baseForm } from '../../../../../../../components/utils/BaseForm'
import { getPendenciaUsoDTO, postPendenciaUsoDTO } from '../../../../../../../dtos/processo/PendenciaDTO'
import { PendenciaProcessoUsoValidator } from '../validator'
import PendenciasUsoModal from '..'
import DataListForm from '../../../../../../../components/layout/DataListForm/DataListForm'
import { Button } from 'primereact/button'

import EtapaTipoPendenciaUsoService from '../../../../../../../services/processo/EtapaTipoPendenciaUsoService'
import { showErrorMessage } from '../../../../../../../components/utils/Message'
import ArquivosPendenciaUso from '../Anexos'

function DadosPendenciasUso ({
  form,
  edicaoHabilitada,
  processoForm,
  camposObrigatorios
}) {
  const [visiblePendenciaModal, setVisiblePendenciaModal] = useState(false)
  const [visiblePendenciaAnexosModal, setVisiblePendenciaAnexosModal] = useState(false)
  const [pendenciaSelecionada, setPendenciaSelecionada] = useState(null)
  const [selectedRows, setSelectedRows] = useState(null)

  function insertOrUpdatePendencia () {
    if (pendenciaSelecionada) {
      editarPendencia()
    } else {
      adicionarPendencia()
    }

    pendenciaForm.resetForm()
    hidePendenciaModal()
  }

  async function adicionarPendencia () {
    try {
      const pendencias = form.values.pendencias

      const pendenciaSaved = await EtapaTipoPendenciaUsoService.save(postPendenciaUsoDTO({ ...pendenciaForm.values, codeus_tpu: form?.values?.codigo_eus }))

      pendencias.push(pendenciaSaved)

      form.setFieldValue('pendencias', pendencias)

      const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
      const finalizadas = parseInt(finalizadasStr, 10)
      const total = parseInt(totalStr, 10)

      const novoTotal = total + 1

      await form.setFieldValue('pendencias_finalizadas', `${finalizadas} / ${novoTotal}`)

      await form.submitForm()
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao salvar a pendência')
    }
  }

  async function editarPendencia () {
    try {
      const pendencias = form.values.pendencias

      const indexPendencia = pendencias.indexOf(pendenciaSelecionada)

      const pendenciaSaved = await EtapaTipoPendenciaUsoService.save(postPendenciaUsoDTO(pendenciaForm.values))

      pendencias.splice(indexPendencia, 1, pendenciaSaved)

      form.setFieldValue('pendencias', pendencias)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao atualizar a pendência')
    }
  }

  const pendenciaForm = baseForm({
    initialValues: getPendenciaUsoDTO(),
    validationSchema: PendenciaProcessoUsoValidator,
    onSubmit: insertOrUpdatePendencia
  })

  useEffect(async () => {
    if (pendenciaSelecionada) {
      pendenciaForm.setValues(getPendenciaUsoDTO(pendenciaSelecionada))
    } else {
      pendenciaForm.setValues(getPendenciaUsoDTO())
    }
  }, [pendenciaSelecionada])

  const abrirModalAdicionarPendenciaAtalho = (event) => {
    if (event.altKey && event.key === 'a' && edicaoHabilitada) {
      setVisiblePendenciaModal(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', abrirModalAdicionarPendenciaAtalho)
    return () => {
      window.removeEventListener('keydown', abrirModalAdicionarPendenciaAtalho)
    }
  }, [edicaoHabilitada])

  const columns = [
    {
      field: 'descri_tpu',
      header: 'Descrição',
      className: 'text-left pl-4',
      format: (valor, data) => valor
        ? <span
          style={
            {
              ...(
                data.descri_concluido === 'Ignorado'
                  ? { 'text-decoration': 'line-through' }
                  : {}
              ),
              marginLeft: '0.25rem'
            }
          }
        >
          {valor}
        </span>
        : ''
    },
    {
      field: 'descri_concluido',
      header: 'Concluído?',
      className: 'text-left pl-4',
      format: (valor, data) => valor
        ? <span
          style={
            {
              ...(
                data.descri_concluido === 'Ignorado'
                  ? { 'text-decoration': 'line-through' }
                  : {}
              ),
              marginLeft: '0.25rem'
            }
          }
        >
          {valor}
        </span>
        : ''
    },
    {
      field: 'att_por',
      header: 'Por',
      className: 'text-left pl-4',
      format: (valor, data) => valor
        ? <span
          style={
            {
              ...(
                data.descri_concluido === 'Ignorado'
                  ? { 'text-decoration': 'line-through' }
                  : {}
              ),
              marginLeft: '0.25rem'
            }
          }
        >
          {valor}
        </span>
        : ''
    },
    {
      field: 'att_data',
      header: 'Data',
      className: 'text-left pl-4',
      format: (valor, data) => valor
        ? <span
          style={
            {
              ...(
                data.descri_concluido === 'Ignorado'
                  ? { 'text-decoration': 'line-through' }
                  : {}
              ),
              marginLeft: '0.25rem'
            }
          }
        >
          {valor}
        </span>
        : ''
    },
    {
      field: 'exiarq_tpu',
      header: 'Anexos',
      className: 'text-left pl-4',
      format: (valor, data) => <Button
        label={`${data.total_arquivos} Anexos`}
        className='mb-2 mr-2'
        icon="pi pi-folder"
        disabled={(selectedRows || []).length}
        onClick={async (e) => {
          if ((selectedRows || []).length) return
          await setPendenciaSelecionada(data)
          setVisiblePendenciaAnexosModal(true)
          setSelectedRows(null)
        }}
      />
    },
    {
      field: 'exiarq_tpu',
      header: 'Exige anexos?',
      className: 'text-left pl-4',
      format: (valor, data) => <span
        style={
          {
            ...(
              data.descri_concluido === 'Ignorado'
                ? { 'text-decoration': 'line-through' }
                : {}
            ),
            marginLeft: '0.25rem'
          }
        }
      >
        {!!valor ? 'Sim' : 'Não'}
      </span>
    }
  ]

  function hidePendenciaModal () {
    setPendenciaSelecionada(null)
    setVisiblePendenciaModal(false)
    pendenciaForm.resetForm()
  }

  function hidePendenciaAnexoModal () {
    setPendenciaSelecionada(null)
    setVisiblePendenciaAnexosModal(false)
    pendenciaForm.resetForm()
  }

  async function ignorarOrBaixarPendencias (ignorarOrBaixar) {
    if (!(selectedRows || []).length) return

    try {
      const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
      const finalizadas = parseInt(finalizadasStr, 10)
      const total = parseInt(totalStr, 10)
      let novasFinalizadas = finalizadas

      for (let pendenciaRow of (selectedRows || [])) {
        if (!pendenciaRow?.codigo_tpu) return

        const pendencias = form.values.pendencias

        const indexPendencia = pendencias.indexOf(pendenciaRow)

        const pendenciaSaved = await EtapaTipoPendenciaUsoService[ignorarOrBaixar](pendenciaRow.codigo_tpu)

        pendencias.splice(indexPendencia, 1, pendenciaSaved)

        form.setFieldValue('pendencias', pendencias)

        novasFinalizadas = pendenciaRow?.descri_concluido === 'Não' ? novasFinalizadas + 1 : novasFinalizadas
      }

      await form.setFieldValue('pendencias_finalizadas', `${novasFinalizadas} / ${total}`)
      await form.submitForm()
      setSelectedRows(null)
    } catch (e) {
      showErrorMessage(e.message || `Ocorreu um erro inesperado ao ${ignorarOrBaixar} as pendências`)
    }
  }

  async function desfazerConclusaoPendencia () {
    if (!(selectedRows || []).length) return

    try {
      const [finalizadasStr, totalStr] = form.values.pendencias_finalizadas.split('/').map(s => s.trim())
      const finalizadas = parseInt(finalizadasStr, 10)
      const total = parseInt(totalStr, 10)
      let novasFinalizadas = finalizadas

      for (let pendenciaRow of (selectedRows || [])) {
        if (!pendenciaRow?.codigo_tpu) return

        const pendencias = form.values.pendencias

        const indexPendencia = pendencias.indexOf(pendenciaRow)

        const pendenciaSaved = await EtapaTipoPendenciaUsoService.desfazerConclusao(pendenciaRow.codigo_tpu)

        pendencias.splice(indexPendencia, 1, pendenciaSaved)

        form.setFieldValue('pendencias', pendencias)

        novasFinalizadas = pendenciaRow?.descri_concluido !== 'Não' ? novasFinalizadas - 1 : novasFinalizadas
      }

      await form.setFieldValue('pendencias_finalizadas', `${novasFinalizadas} / ${total}`)
      await form.submitForm()
      setSelectedRows(null)
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado ao desfazer conclusão das pendências')
    }
  }

  const processoEmAndamento = (processoForm.values.status.codigo_spr === 1)

  return (
    <div className="formgrid grid fluid">
      <div className='w-full flex justify-content-end'>
        <Button
          label="Adicionar pendência"
          className='w-full md:w-auto mb-2 md:mb-2 md:mr-2'
          icon="pi pi-plus"
          onClick={() => setVisiblePendenciaModal(true)}
          disabled={!edicaoHabilitada || !!((selectedRows || []).length)}
          tooltip='ALT + "A" para abrir o cadastro'
          tooltipOptions={{ position: 'bottom' }}
        />
        <Button
          label="Marcar como pendente"
          className="hidden md:inline-block md:mb-2 md:mr-2"
          onClick={() => desfazerConclusaoPendencia()}
          disabled={!processoEmAndamento || !((selectedRows || []).length)}
        />
        <Button
          label="Ignorar pendências"
          className="hidden md:inline-block md:mb-2 md:mr-2"
          onClick={() => ignorarOrBaixarPendencias('ignorar')}
          disabled={!processoEmAndamento || !((selectedRows || []).length)}
        />
        <Button
          label="Baixar pendências"
          className="hidden md:inline-block md:mb-2 md:mr-2"
          onClick={() => ignorarOrBaixarPendencias('baixar')}
          disabled={!processoEmAndamento || !((selectedRows || []).length)}
        />
      </div>
      <div className="field col-12">
        <DataListForm
          value={form.values.pendencias}
          columns={columns}
          emptyMessage="Nenhuma pendência cadastrada."
          onRowSelect={async (e) => {
            if (e.type === 'checkbox' || e.originalEvent.target.className.includes('button')) return
            await setPendenciaSelecionada(e.data)
            setVisiblePendenciaModal(true)
            setSelectedRows(null)
          }}
          multipleCheckbox
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
      {!!visiblePendenciaModal && <PendenciasUsoModal
        visible={visiblePendenciaModal}
        onHide={() => hidePendenciaModal()}
        camposObrigatorios={camposObrigatorios}
        pendenciaForm={pendenciaForm}
        pendenciaSelecionada={pendenciaSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        form={form}
        setPendencias={(pendencias) => {
          form.setFieldValue('pendencias', pendencias)
        }}
        processoEmAndamento={processoEmAndamento}
      />}
      {!!visiblePendenciaAnexosModal && <ArquivosPendenciaUso
        visible={visiblePendenciaAnexosModal}
        onHide={() => hidePendenciaAnexoModal()}
        camposObrigatorios={camposObrigatorios}
        pendenciaForm={pendenciaForm}
        pendenciaSelecionada={pendenciaSelecionada}
        edicaoHabilitada={edicaoHabilitada}
        form={form}
        setAnexos={(anexos) => {
          form.setFieldValue('anexos', anexos)
        }}
        setPendencias={(pendencias) => {
          form.setFieldValue('pendencias', pendencias)
        }}
        processoEmAndamento={processoEmAndamento}
      />}
    </div>
  )
}

export default DadosPendenciasUso
