import { transformAll } from '@overgear/yup-ast'
import React, { useEffect, useState } from 'react'
import TabMenuContainer from '../../../components/layout/TabMenuContainer/TabMenuContainer'
import TabMenuPage from '../../../components/layout/TabMenuContainer/TabMenuPage'
import Confirm from '../../../components/utils/Confirm'
import EnviarEmailModal from '../../../components/utils/EnviarEmailModal'
import EnviarWhatsAppModal from '../../../components/utils/EnviarWhatsAppModal'
import { showErrorMessage, showSuccessMessage, showWarnMessage } from '../../../components/utils/Message'
import MotivoVendaPerdidaModal from '../../../components/utils/MotivoVendaPerdidaModal'
import { Round, formatDate, formatDateToInputDate, formatToCurrency } from '../../../helpers/formaters'
import TipoPrecoService from '../../../services/cadastro/financeiro/TipoPrecoService'
import PermissaoService from '../../../services/cadastro/pessoa/PermissaoService'
import RestricaoService from '../../../services/cadastro/pessoa/RestricaoService'
import ProdutoService from '../../../services/cadastro/produto/ProdutoService'
import ConfigService from '../../../services/configuracao/ConfigService'
import OrcamentoBalcaoService from '../../../services/vendas/orcamentoBalcao/OrcamentoBalcaoService'
import PecaBalcaoService from '../../../services/vendas/orcamentoBalcao/PecaBalcaoService'
import PessoaFisicaModal from '../../cadastro/pessoa/pessoaFisica/PessoaFisicaModal'
import PessoaJuridicaModal from '../../cadastro/pessoa/pessoaJuridica/PessoaJuridicaModal'
import DisponibilidadePecasModal from '../disponibilidadePecas/DisponibilidadePecasModal'
import './_orcamentoBalcaoForm.scss'
import ImpressoesOrcamentoModal from './modals/ImpressoesOrcamentoModal'
import RequisicaoLiberacaoPendentesModal from './modals/RequisicaoLiberacaoPendentesModal'
import RequisicaoLiberacaoRecusadasModal from './modals/RequisicaoLiberacaoRecusadasModal'
import DadosPagamentoOpv from './orcamentoBalcaoFormPages/DadosPagamentoOpv'
import DadosPecasOpv from './orcamentoBalcaoFormPages/DadosPecasOpv'
import DadosPrincipaisOpv from './orcamentoBalcaoFormPages/DadosPrincipaisOpv'
import DadosTransporteOpv from './orcamentoBalcaoFormPages/DadosTransporteOpv'
import InformacoesAdicionaisOpv from './orcamentoBalcaoFormPages/InformacoesAdicionaisOpv'
import PessoaService from '../../../services/cadastro/pessoa/PessoaService'
import ModalidadeVendaService from '../../../services/cadastro/veiculo/ModalidadeVendaService'
import TipoMidiaService from '../../../services/vendas/TipoMidiaService'
import FormaCobrancaService from '../../../services/cadastro/financeiro/FormaCobrancaService'
import EstadosService from '../../../services/cadastro/pessoa/EstadosService'
import { getStore } from '../../../redux/store'

function OrcamentoBalcaoForm ({
  form,
  edicaoHabilitada,
  camposObrigatorios,
  setActions,
  setHeader,
  onSave,
  refreshForm,
  isNew,
  setSemExcluir,
  setApenasBotaoAcoes
}) {
  const situacaoOpvAberto = 'a'
  const situacaoOpvFaturado = 'f'
  const situacaoOpvCancelado = 'c'
  const situacaoOpvEnviadoCaixa = 'e'
  // const situacaoOpvImpresso = 'i'
  const situacaoOpvReservado = 'r'
  const situacaoAbertaReservada = form.values.situacaoOrcamento.codigo_sop === 'a' || form.values.situacaoOrcamento.codigo_sop === 'r' || form.values.situacaoOrcamento.codigo_sop === undefined
  const [valorBrutoOrcamento, setValorBrutoOrcamento] = useState(0.0)
  const [descontosOrcamento, setDescontosOrcamento] = useState(0.0)
  const [freteSeguroDespesas, setFreteSeguroDespesas] = useState(0.0)
  const [totalOrcamento, setTotalOrcamento] = useState(0.0)
  const [pecaBalcaoCamposObrigatorios, setPecaBalcaoCamposObrigatorios] = useState(null)
  const [visiblePessoaFisicaModal, setVisiblePessoaFisicaModal] = useState(false)
  const [visiblePessoaJuridicaModal, setVisiblePessoaJuridicaModal] = useState(false)
  const [visibleClienteModal, setVisibleClienteModal] = useState(false)
  const [visibleConsultorModal, setVisibleConsultorModal] = useState(false)
  const [visibleConveniadoModal, setVisibleConveniadoModal] = useState(false)
  const [visibleImpressoesOrcamentoModal, setVisibleImpressoesOrcamentoModal] = useState(false)

  const [visibleMotivoVendaPerdidaModal, setVisibleMotivoVendaPerdidaModal] = useState(false)
  const [visibleConfirmarCancelar, setVisibleConfirmarCancelar] = useState(false)
  const [visibleEnviarWhatsAppModal, setVisibleEnviarWhatsAppModal] = useState(false)
  const [visibleEnviarEmailModal, setVisibleEnviarEmailModal] = useState(false)
  const [visibleConfirmarReabrir, setVisibleConfirmarReabrir] = useState(false)
  const [visibiblePecasIndisponiveisModal, setVisibiblePecasIndisponiveisModal] = useState(false)
  const [listaPecasIndisponiveis, setListaPecasIndisponiveis] = useState([])
  const [tipoPessoa, setTipoPessoa] = useState('cliente')

  const [orcamentoPendente, setOrcamentoPendente] = useState([])
  const [orcamentoRecusado, setOrcamentoRecusado] = useState([])
  const [visibleReqRecusadas, setVisibleReqRecusadas] = useState(false)
  const [visibleReqPendentes, setVisibleReqPendentes] = useState(false)

  const [gerarVendaPerdidaPecCancelada, setGerarVendaPerdidaPecCancelada] = useState(false)
  const [tiposPreco, setTiposPreco] = useState([])
  const [pecaBalcaoValidator, setPecaBalcaoValidator] = useState({})
  const [tiposmidia, setTiposMidia] = useState([])
  const [desmarcarOuMarcarPresencialSeHouverFrete, setDesmarcarOuMarcarPresencialSeHouverFrete] = useState(false)
  const [codigoPadraoDoClienteConsumidor, setCodigoPadraoDoClienteConsumidor] = useState(0)
  const [intermediadores, setIntermediadores] = useState([])
  const [modalidadesVenda, setModalidadesVenda] = useState([])
  const [permissao197AlterarDescontoQtd, setPermissao197AlterarDescontoQtd] = useState(false)
  const [formasCobranca, setFormasCobranca] = useState([])
  const [modalidadesFrete, setModalidadesFrete] = useState([])
  const [estados, setEstados] = useState([])

  const actionsWhenOrcamentoBalcaoEnviadoParaCaixa = [
    { label: 'Cancelar', command: async () => await cancelarOrcamentoBalcao() },
    { label: 'Reabrir', command: () => setVisibleConfirmarReabrir(true) },
    { label: 'Imprimir', command: () => modalImpressao() },
    {
      label: 'Enviar e-mail',
      command: async () => await enviarEmail()
    },
    {
      label: 'Enviar Whatsapp',
      command: async () => await enviarWhatsApp()
    }
  ]

  const actionsWhenOrcamentoBalcaoAbertoWeb = [
    {
      label: 'Enviar para o caixa',
      command: async () => await enviarParaOCaixa()
    },
    { label: 'Cancelar', command: async () => await cancelarOrcamentoBalcao() },
    { label: 'Reservar', command: async () => await reservarOrcamentoBalcao() },
    { label: 'Imprimir', command: () => modalImpressao() },
    {
      label: 'Enviar e-mail',
      command: async () => await enviarEmail()
    },
    {
      label: 'Enviar Whatsapp',
      command: async () => await enviarWhatsApp()
    }
  ]

  const actionsWhenOrcamentoBalcaoReservadoWeb = [
    {
      label: 'Enviar para o caixa',
      command: async () => await enviarParaOCaixa()
    },
    { label: 'Cancelar', command: async () => await cancelarOrcamentoBalcao() },
    { label: 'Imprimir', command: () => modalImpressao() },
    {
      label: 'Enviar e-mail',
      command: async () => await enviarEmail()
    },
    {
      label: 'Enviar Whatsapp',
      command: async () => await enviarWhatsApp()
    }
  ]

  const actionsWhenOrcamentoBalcaoFaturadoWeb = [
    { label: 'Imprimir', command: () => modalImpressao() },
    {
      label: 'Enviar e-mail',
      command: async () => await enviarEmail()
    },
    {
      label: 'Enviar Whatsapp',
      command: async () => await enviarWhatsApp()
    }

  ]

  const actionsWhenOrcamentoBalcaoCancelado = [
    { label: 'Reabrir', command: () => setVisibleConfirmarReabrir(true) },
    { label: 'Imprimir', command: () => modalImpressao() }
  ]

  const actionsWhenOrcamentoBalcaoAbertoDesktop = [
    { label: 'Imprimir', command: () => modalImpressao() },
    {
      label: 'Enviar e-mail',
      command: async () => await enviarEmail()
    },
    {
      label: 'Enviar Whatsapp',
      command: async () => await enviarWhatsApp()
    }
  ]

  const modalImpressao = () => {
    setVisibleImpressoesOrcamentoModal(true)
  }

  useEffect(() => {
    const pecasBalcao = form.values.pecasBalcao
    if (pecasBalcao) {
      pecasBalcao.sort((a, b) => Number(a.iscan_peo) - Number(b.iscan_peo))
    }
    form.setFieldValue('pecasBalcao', pecasBalcao)
  }, [form.values.pecasBalcao])

  useEffect(async () => {
    setSemExcluir(true)
    setApenasBotaoAcoes(orcamentoCancelado() || orcamentoDesktop())
    await requisicoesIniciais()
    if (isNew) {
      alterarDataValidadaConfig()
    }
    setActionsBySituacao()
    calculaValoresOrcamento()
  }, [form.values.codsop_opv, form.values.codigo_opv])

  async function alterarDataValidadaConfig () {
    let proximaData = new Date()
    let diasConfig3384 = await ConfigService.getValor(3384)
    if (diasConfig3384 !== '') {
      proximaData.setDate(proximaData.getDate() + Number(diasConfig3384))
      proximaData = proximaData.toDateString()
      form.setFieldValue('datval_opv', formatDateToInputDate(proximaData))
    }
  }

  async function requisicoesIniciais () {
    await Promise.all([
      getOrcamentosPendentes(),
      getOrcamentosRecusados(),
      getConfig3335(),
      getConfig2036(),
      getPecaBalcaoFormValidator(),
      getTiposPreco(),
      getConfig2024(),
      getClienteConsumidor(),
      getTiposMidia(),
      getIntermediadores(),
      getModalidadesVenda(),
      getConfig1433(),
      getPermissao197(),
      getFormasCobranca(),
      getModalidadeFrete(),
      getEstadosPorPais(getStore().codigoBrasil)
    ])
  }

  async function getOrcamentosPendentes () {
    const orcamentoPendente = await OrcamentoBalcaoService.getQtdPendentes(form.values.codtra_opv)
    await form.setFieldValue('orcamentoPendente', orcamentoPendente)
    await setOrcamentoPendente(orcamentoPendente)
    return orcamentoPendente
  }

  async function getOrcamentosRecusados () {
    const orcamentoRecusado = await OrcamentoBalcaoService.getQtdRecusada(form.values.codtra_opv)
    await form.setFieldValue('orcamentoRecusado', orcamentoRecusado)
    await setOrcamentoRecusado(orcamentoRecusado)

    return orcamentoRecusado
  }

  async function getConfig3335 () {
    if (!isNew) return
    const sugerirFretePresencial = await ConfigService.getValor(3335)
    form.setFieldValue('ispres_opv', sugerirFretePresencial)
  }

  async function getConfig2036 () {
    const gerarRegistroDeVendaPerdidaParaPecasCanceladas = await ConfigService.getValor(2036)
    setGerarVendaPerdidaPecCancelada(gerarRegistroDeVendaPerdidaParaPecasCanceladas)
  }

  async function getPecaBalcaoFormValidator () {
    try {
      const pecaBalcaoService = new PecaBalcaoService()

      const pecaBalcaoFormValidatorAST = await pecaBalcaoService.getFormValidator()

      const pecaBalcaoFormValidator = transformAll(pecaBalcaoFormValidatorAST)

      setPecaBalcaoValidator(pecaBalcaoFormValidator)

      const camposObrigatorios = []

      getCamposObrigatoriosRecursively(pecaBalcaoFormValidator.fields, camposObrigatorios)

      setPecaBalcaoCamposObrigatorios(camposObrigatorios)
      return camposObrigatorios
    } catch (e) {
      showErrorMessage(e.message || 'Ocorreu um erro inesperado!')
    }
  }

  async function getTiposPreco () {
    const tiposPreco = await TipoPrecoService.getTiposPrecoOrcamentoBalcao()
    setTiposPreco(tiposPreco)
  }

  async function getConfig2024 () {
    const desmarcarOuMarcarPresencialSeHouverFrete = await ConfigService.getValor(2024)
    setDesmarcarOuMarcarPresencialSeHouverFrete(desmarcarOuMarcarPresencialSeHouverFrete)
  }

  async function getConfig1433 () {
    const obrigarMidia = await ConfigService.getValor(1433)
    form.setFieldValue('tipomidia.obrigarTipoMidia', obrigarMidia)
  }

  async function getClienteConsumidor () {
    const codigoPadraoDoClienteConsumidor = await ConfigService.getValor(1292)
    setCodigoPadraoDoClienteConsumidor(codigoPadraoDoClienteConsumidor)
  }

  const getIntermediadores = async () => {
    try {
      const intermediadores = await PessoaService.getItermediador()
      setIntermediadores(intermediadores)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os intermediadores!')
    }
  }

  async function getModalidadesVenda () {
    try {
      const modalidadesVenda = await ModalidadeVendaService.getAll()

      for (const modalidadeVenda of modalidadesVenda) {
        if (modalidadeVenda.codcsu_mov === 'n') {
          modalidadeVenda.descri_mov = modalidadeVenda.descri_mov + ' (REVENDA)'
        }
      }

      if (!form.values.modalidadeVenda) {
        form.setFieldValue('modalidadeVenda', modalidadesVenda[0])
      }

      setModalidadesVenda(modalidadesVenda)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as modalidades de venda!')
    }
  }

  async function getTiposMidia () {
    try {
      const tiposmidia = await TipoMidiaService.filterTipoMidia()
      setTiposMidia(tiposmidia)
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar as mídias!')
    }
  }

  async function getPermissao197 () {
    const permissao197 = await PermissaoService.getByCodigo(197)
    setPermissao197AlterarDescontoQtd(permissao197)
  }

  async function getFormasCobranca () {
    const formasCobranca = await FormaCobrancaService.getAll()
    setFormasCobranca(formasCobranca)
  }

  const getModalidadeFrete = async () => {
    try {
      await OrcamentoBalcaoService.getAllModalidadeFrete().then(modalidades => {
        setModalidadesFrete(modalidades)
      })
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar a modalidade de frete!')
    }
  }

  const getEstadosPorPais = async (id) => {
    try {
      await EstadosService.getAllByCountry(id).then(estados => setEstados(estados))
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao buscar os estados por pais!')
    }
  }

  useEffect(() => {
    calculaTotalOrcamento()
  }, [
    valorBrutoOrcamento,
    descontosOrcamento,
    freteSeguroDespesas,
    form.values.acrcpg_opv
  ])

  function isOrcamentoWeb () {
    return form.values.codapp_opv === 2
  }

  const descricaoSituacao = (codigo_sop) => {
    const descricaoSituacaoData = {
      //aberto
      a: `${form.values.situacaoOrcamento.descri_sop} ${formatDate(
        form.values.dahins_opv
      )}`,
      //cancelado
      c: `${form.values.situacaoOrcamento.descri_sop} ${formatDate(
        form.values.dahcan_opv
      )}`,
      //enviado caixa
      e: `${form.values.situacaoOrcamento.descri_sop} ${formatDate(
        form.values.dahenv_opv
      )}`,
      //faturado
      f: `${form.values.situacaoOrcamento.descri_sop} ${formatDate(
        form.values.dahins_opv
      )}`,
      //impresso
      i: `${form.values.situacaoOrcamento.descri_sop}`,
      //reservado
      r: `${form.values.situacaoOrcamento.descri_sop}`
    }
    return descricaoSituacaoData[codigo_sop] || ''
  }

  async function setOrcamentoBalcaoHeader () {
    setHeader(
      <div className="lg:flex  w-full align-items-center">
        <div className="">
          {!isOrcamentoWeb() && !isNew && (
            <>Não é permitido alterar orçamentos feitos pelo SancesTurbo DMS!</>
          )}
          <h1 className="">
            Orçamento balcão{' '}
            {form.values.numero_opv ? `- ${form.values.numero_opv}` : ''}
          </h1>
        </div>
        <div className="flex md:justify-content-around justify-content-center align-items-center md:w-6">
          <div className="descrição-situacao-manutencao lg:w-4 w-6 text-center ">
            <p>{descricaoSituacao(form.values.situacaoOrcamento.codigo_sop)}</p>
          </div>
          <div className="descrição-situacao-manutencao lg:w-4 w-6 text-center">
            {form.values?.orcamentoPendente?.length > 0 && <p className="underline text-red cursor-pointer" onClick={() => setVisibleReqPendentes(state => !state)}>Aguardando liberação</p>}
            {form.values?.orcamentoRecusado?.length > 0 && <p className="underline text-red  cursor-pointer" onClick={() => setVisibleReqRecusadas(state => !state)}>Liberação recusada</p>}
          </div>
        </div>
      </div>
    )
  }
  useEffect(() => {
    if (!form?.values?.numero_opv || !form?.values?.situacaoOrcamento?.codigo_sop) return
    setOrcamentoBalcaoHeader()
  }, [
    form?.values?.numero_opv,
    form?.values?.situacaoOrcamento?.codigo_sop,
    form?.values?.orcamentoPendente,
    form?.values?.orcamentoRecusado,
    orcamentoPendente,
    orcamentoRecusado
  ])

  function setActionsBySituacao () {
    if (form.values.codsop_opv) {
      if (isOrcamentoWeb()) {
        switch (form.values.codsop_opv) {
        case situacaoOpvAberto:
          setActions(actionsWhenOrcamentoBalcaoAbertoWeb)
          break
        case situacaoOpvEnviadoCaixa:
          setActions(actionsWhenOrcamentoBalcaoEnviadoParaCaixa)
          break
        case situacaoOpvCancelado:
          setActions(actionsWhenOrcamentoBalcaoCancelado)
          break
        case situacaoOpvFaturado:
          setActions(actionsWhenOrcamentoBalcaoFaturadoWeb)
          break
        case situacaoOpvReservado:
          setActions(actionsWhenOrcamentoBalcaoReservadoWeb)
          break
        default:
          setActions(actionsWhenOrcamentoBalcaoAbertoWeb)
          break
        }
      } else {
        setActions(actionsWhenOrcamentoBalcaoAbertoDesktop)
      }
    }
  }

  function calculaValoresOrcamento (pecasBalcao) {
    calculaValorBrutoAndDescontos(pecasBalcao)
    calculaFreteSegurosDespesas()
  }

  function calculaValorBrutoAndDescontos (pecasBalcaoNovas) {
    const pecasBalcao = pecasBalcaoNovas || form.values.pecasBalcao
    let valorTotalBruto = 0
    let valorDescontos = 0

    for (const pecaBalcao of pecasBalcao) {
      if (!pecaBalcao.iscan_peo) {
        valorTotalBruto += pecaBalcao.valuni_peo * pecaBalcao.qtd_peo
        valorDescontos += pecaBalcao.valdes_peo
      }
    }

    setValorBrutoOrcamento(valorTotalBruto)
    setDescontosOrcamento(valorDescontos)
  }

  function calculaFreteSegurosDespesas () {
    setFreteSeguroDespesas(
      form.values.valfre_opv + form.values.valseg_opv + form.values.valoud_opv
    )
  }

  function calculaTotalOrcamento () {
    setTotalOrcamento(
      valorBrutoOrcamento +
      freteSeguroDespesas +
      form.values.acrcpg_opv -
      descontosOrcamento
    )
  }

  async function cancelarOrcamentoBalcao () {
    const restricaoCancelarOrcamentoBalcao = await RestricaoService.getByCodigo(24)

    if (restricaoCancelarOrcamentoBalcao) {
      showWarnMessage('Cancelamento não permitido! (Restrição número "24")')
      return
    }

    const gerarRegistroVendaPerdidaParaPecasCanceladas =
      await ConfigService.getValor(2036)
    const obrigaInformarMotivoCancelamentoVendaNoOrcamentoBalcao =
      await ConfigService.getValor(1442)

    if (
      gerarRegistroVendaPerdidaParaPecasCanceladas ||
      obrigaInformarMotivoCancelamentoVendaNoOrcamentoBalcao
    ) {
      setVisibleMotivoVendaPerdidaModal(true)
    } else {
      setVisibleConfirmarCancelar(true)
    }
  }

  async function enviarWhatsApp () {
    setVisibleEnviarWhatsAppModal(true)
  }

  async function enviarEmail () {
    setVisibleEnviarEmailModal(true)
  }

  async function confirmarEnvioWhatsApp (mensagemEnvioWhatsApp) {
    try {
      // Busca na API a URL com telefone e mensagem montada
      const urlMensagemWhatsApp = await OrcamentoBalcaoService.gerarURLMensagemWhatsApp(form.values.codigo_opv, mensagemEnvioWhatsApp)

      // Abre o WhatsApp com a mensagem direcionada para o cliente do orçamento
      window.open(urlMensagemWhatsApp.urlMensagemWhatsApp, '_blank')
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar WhatsApp!')
    }
  }

  async function confirmarEnvioEmail (dadosRetornoEnvioEmail) {
    try {
      const responseEnvioEmail = await OrcamentoBalcaoService.enviarEmail(form.values.codigo_opv, dadosRetornoEnvioEmail)

      if (responseEnvioEmail?.messageId.length > 0) {
        showSuccessMessage('E-mail enviado com sucesso!')
      }
    } catch (error) {
      showErrorMessage(error.message || 'Ocorreu um erro inesperado ao tentar enviar Email!')
    }
  }

  async function cancelarAposInformarMotivoVendaPerdida (motivoVendaPerdida) {
    try {
      await OrcamentoBalcaoService.cancelarOrcamentoBalcao(
        form.values.codigo_opv,
        motivoVendaPerdida.codigo_mcv
      )
      await refreshForm(form.values.codigo_opv)
      setApenasBotaoAcoes(true)
      showSuccessMessage('Orçamento balcão cancelado com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar cancelar o orçamento balcão!'
      )
    }
  }

  async function enviarParaOCaixa () {
    try {
      const permissaoParEnviarOrcamentoParaOCaixa = await PermissaoService.getByCodigo(125)
      if (!permissaoParEnviarOrcamentoParaOCaixa) {
        showWarnMessage('Você não possui permissão para enviar um orçamento balcão para o caixa!')
      } else {
        const listaPecasValidadas = await OrcamentoBalcaoService.validarDisponibilidadePecas(form.values.codigo_opv)
        if (Array.isArray(listaPecasValidadas) && listaPecasValidadas.length === 0) {
          await enviarParaOCaixaAposValidarDisponibilidade()
        } else {
          await setListaPecasIndisponiveis(listaPecasValidadas)
          await setVisibiblePecasIndisponiveisModal(true)
        }
      }
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar enviar o orçamento balcão para o caixa!'
      )
    }
  }

  async function enviarParaOCaixaAposValidarDisponibilidade () {
    try {
      await OrcamentoBalcaoService.enviarParaOCaixa(form.values.codigo_opv)
      await refreshForm(form.values.codigo_opv)
      setApenasBotaoAcoes(true)
      await getOrcamentosPendentes()
      showSuccessMessage('Orçamento balcão enviado para o caixa!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar enviar o orçamento balcão para o caixa!'
      )
    }
  }

  async function cancelarAposConfirmar () {
    try {
      await OrcamentoBalcaoService.cancelarOrcamentoBalcao(
        form.values.codigo_opv
      )
      await refreshForm(form.values.codigo_opv)
      setApenasBotaoAcoes(true)
      showSuccessMessage('Orçamento balcão cancelado com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar cancelar o orçamento balcão!'
      )
    }
  }

  async function reabrirAposConfirmar () {
    try {
      const restricaoParaReabrirOrcamentos = await RestricaoService.getByCodigo(
        42
      )

      if (restricaoParaReabrirOrcamentos) {
        showWarnMessage(
          'Não é possível reabrir o orçamento devido a restrição 42!'
        )
        return
      }

      const permissaoParaReabrirOrcamentosCancelados =
        await PermissaoService.getByCodigo(168)

      if (
        !permissaoParaReabrirOrcamentosCancelados &&
        form.values.codsop_opv === situacaoOpvCancelado
      ) {
        showWarnMessage(
          'Você não possui permissão para reabrir um orçamento cancelado!'
        )
        return
      }

      await OrcamentoBalcaoService.reabrirOrcamentoBalcao(
        form.values.codigo_opv
      )
      setVisibleConfirmarReabrir(false)
      await refreshForm(form.values.codigo_opv)
      setApenasBotaoAcoes(false)
      showSuccessMessage('Orçamento balcão reaberto com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar reabrir o orçamento balcão!'
      )
    }
  }

  async function reservarOrcamentoBalcao () {
    try {
      const permissaoParaReservarOrcamentos =
        await PermissaoService.getByCodigo(146)

      if (!permissaoParaReservarOrcamentos) {
        showWarnMessage(
          'Você não possui permissão para reservar um orçamento!'
        )
        return
      }

      await OrcamentoBalcaoService.reservarOrcamentoBalcao(
        form.values.codigo_opv
      )
      await refreshForm(form.values.codigo_opv)
      showSuccessMessage('Orçamento balcão reservado com sucesso!')
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar reservar o orçamento balcão!'
      )
    }
  }

  const labelButtonsNext = [
    'Ir para peças',
    'Ir para pagamento',
    'Ir para transporte',
    'Ir para observações'
  ]

  const labelButtonsBack = [
    'Voltar para dados principais',
    'Voltar para peças',
    'Voltar para pagamento',
    ['Voltar para transporte']
  ]

  const isClientePessoaFisica = (cgcCpf) => {
    if (cgcCpf?.length === 14) return false
    return true
  }

  const showPessoaModal = (pessoa, cgcCpf, isCliente, isConsultor, isConveniado) => {
    setTipoPessoa(pessoa)

    setVisibleClienteModal(isCliente)
    setVisibleConsultorModal(isConsultor)
    setVisibleConveniadoModal(isConveniado)

    if (isClientePessoaFisica(cgcCpf)) {
      return setVisiblePessoaFisicaModal(true)
    }

    setVisiblePessoaJuridicaModal(true)
  }

  const getPessoaModal = (pessoa) => {
    const codigoPes = {
      cliente: form.values.cliente?.codigo_pes,
      conveniado: form.values.conveniado?.codigo_pes,
      consultor: form.values.consultor?.codigo_pes
    }
    return codigoPes[pessoa]
  }

  async function onSaveModalCliente (e) {
    if (edicaoHabilitada) {
      if (e.pessoa.codigo_pes) {
        if (visibleClienteModal) {
          await setTipoPrecoByCliente(e.pessoa, false)
          form.setFieldValue('cliente', e.pessoa)
        }

        if (visibleConsultorModal) {
          form.setFieldValue('consultor', e.pessoa)
        }

        if (visibleConveniadoModal) {
          form.setFieldValue('conveniado', e.pessoa)
        }
      }
    }
  }

  function getCamposObrigatoriosRecursively (fields, camposObrigatorios, parentFieldName) {
    for (const fieldName of Object.getOwnPropertyNames(fields)) {
      if (fields[fieldName].fields) {
        let newParentFieldName = fieldName

        if (parentFieldName) {
          newParentFieldName = `${parentFieldName}.${fieldName}`
        }

        getCamposObrigatoriosRecursively(fields[fieldName].fields, camposObrigatorios, newParentFieldName)
        continue
      }

      if (parentFieldName) {
        camposObrigatorios.push(`${parentFieldName}.${fieldName}`)
        continue
      }

      camposObrigatorios.push(fieldName)
    }
  }

  const validateTabChange = (index) => {
    if (!form.values.cliente?.codigo_pes && [2, 3, 4].includes(index)) {
      showWarnMessage('Informe o cliente para prosseguir')
      return false
    }

    if (!form.values.tipoPreco?.codigo_tpr) {
      form.setFieldValue('tipoPreco', { codigo_tpr: 1, descri_tpr: 'Venda - Varejo' })
    }

    return true
  }

  // ! Deprecated
  // function orcamentoMesmaEmpresa () {
  //   return Number(form.values.codemp_opv) === Number(getStore().empresaLogada.codigo_emp)
  // }

  function orcamentoCancelado () {
    return form.values.codsop_opv === 'c'
  }

  function orcamentoDesktop () {
    return Number(form.values.codapp_opv) === 1
  }

  function isEdicaoHabilitada () {
    return situacaoAbertaReservada && edicaoHabilitada && (isOrcamentoWeb() || isNew)
  }

  function atualizaDescontoPeca (pecaBalcao) {
    const valorTotalBrutoPecaBalcao = pecaBalcao.qtd_peo * pecaBalcao.valuni_peo
    const valorPorcentagemDesconto = pecaBalcao.perdes_peo

    if (valorTotalBrutoPecaBalcao !== 0) {
      pecaBalcao.valdes_peo = (valorTotalBrutoPecaBalcao * valorPorcentagemDesconto) / 100
    } else {
      pecaBalcao.valdes_peo = 0
    }
  }

  function calculaValorTotalPeca (pecaBalcao) {
    pecaBalcao.valtot_peo = pecaBalcao.qtd_peo * pecaBalcao.valuni_peo - pecaBalcao.valdes_peo
  }

  async function validarTipoDePreco (tipoPreco, novoCliente, lancarErro) {
    const cliente = novoCliente || await form.values.cliente

    let nomePrecoPersonalizado
    let codigoPermissaoUsarPrecoPersonalizado
    switch (tipoPreco.codigo_tpr) {
    case 7:
      if (cliente.codigo_pes && !cliente.cliata_pes) {
        throw Error('Cliente não possui permissão para compras no atacado!')
      }
      break
    case 8:
      nomePrecoPersonalizado = tipoPreco.descri_tpr // await ConfigService.getValor(3091) || 'Personalizado 1'
      codigoPermissaoUsarPrecoPersonalizado = 165
      break
    case 9:
      nomePrecoPersonalizado = tipoPreco.descri_tpr // await ConfigService.getValor(3092) || 'Personalizado 2'
      codigoPermissaoUsarPrecoPersonalizado = 166
      break
    case 10:
      nomePrecoPersonalizado = tipoPreco.descri_tpr // await ConfigService.getValor(3093) || 'Personalizado 3'
      codigoPermissaoUsarPrecoPersonalizado = 167
      break
    }

    if (nomePrecoPersonalizado && codigoPermissaoUsarPrecoPersonalizado) {
      const permissaoParaUsarPrecoPersonalizado = await PermissaoService.getByCodigo(codigoPermissaoUsarPrecoPersonalizado)

      if (!permissaoParaUsarPrecoPersonalizado) {
        throw Error(`Você não possui permissão para vendas com o preço ${nomePrecoPersonalizado}!`)
      }

      if (cliente.codigo_pes && cliente.codtpr_pes !== tipoPreco.codigo_tpr) {
        throw Error(`Não é permitido venda com tipo de preço ${nomePrecoPersonalizado} para esse cliente!`)
      }
    }

    if (Array.isArray(form.values.pecasBalcao)) {
      await PecaBalcaoService.verificaPrecoDasPecasPorTipoPreco(form.values, tipoPreco)
    }
  }

  async function setTipoPrecoByCliente (cliente, lancarErro) {
    let codigoTipoPrecoDoCliente = form.values.tipoPreco.codigo_tpr || 1

    if (cliente.cliata_pes) {
      codigoTipoPrecoDoCliente = 7
    } else {
      if (cliente.codtpr_pes) {
        codigoTipoPrecoDoCliente = cliente.codtpr_pes
      }
    }

    const tipoPreco = tiposPreco?.find(tipoPreco => tipoPreco.codigo_tpr === codigoTipoPrecoDoCliente)

    await handleChangeTipoPreco({ target: { value: tipoPreco } }, cliente, lancarErro)
  }

  async function handleChangeTipoPreco (event, cliente, lancarErro) {
    const tipoPrecoNovo = event.target.value
    if (!tipoPrecoNovo.codigo_tpr) {
      return
    }

    try {
      await validarTipoDePreco(tipoPrecoNovo, cliente)
    } catch (error) {
      if (lancarErro) {
        throw Error(error.message || 'Ocorreu um erro inesperado ao validar o tipo de preço!')
      } else {
        showErrorMessage(error.message || 'Ocorreu um erro inesperado ao validar o tipo de preço!')
        // Se não validou os preços com o tipo escolhido, tenta trocar para o preço varejo. Se uma peça não tiver o preço varejo, cancela tudo
        if (tipoPrecoNovo.codigo_tpr !== 1) {
          const tipoPrecoObj = tiposPreco?.find(tipoPreco => tipoPreco.codigo_tpr === 1)
          await handleChangeTipoPreco({ target: { value: tipoPrecoObj } }, cliente, true)
        }
        return false
      }
    }
    const { pecasBalcao } = form.values

    if (pecasBalcao?.length > 0) {
      var temDesconto = pecasBalcao.some(function (peca) {
        return peca.perdes_peo > 0 && !peca.iscan_peo
      })
      if (temDesconto) {
        const permitirDesconto = await permitirDescontoByTipoPreco(tipoPrecoNovo)
        if (!permitirDesconto) {
          limparCampoDesconto()
        }
      }
    }

    if (tipoPrecoNovo.codigo_tpr !== form.values.tipoPreco.codigo_tpr && Array.isArray(pecasBalcao)) {
      for (const pecaBalcao of pecasBalcao) {
        if (!pecaBalcao.iscan_peo) {
          const dadosPrecoProduto = await ProdutoService.getDadosPrecoByTipoPreco(
            pecaBalcao.codpec_peo,
            pecaBalcao.qtd_peo,
            form.values.codemp_opv,
            tipoPrecoNovo.codigo_tpr
          )
          pecaBalcao.valuni_peo = dadosPrecoProduto.preco
          pecaBalcao.valbru_peo = dadosPrecoProduto.preco
          pecaBalcao.codtpr_peo = dadosPrecoProduto.codigoTipoPreco
          pecaBalcao.perdes_peo = dadosPrecoProduto.perdesQtd
          pecaBalcao.usapreqtd_peo = dadosPrecoProduto.usaPreQtd

          atualizaDescontoPeca(pecaBalcao)
          calculaValorTotalPeca(pecaBalcao)
        }
      }

      form.setFieldValue('pecasBalcao', pecasBalcao)
      form.setFieldValue('tipoPreco', tipoPrecoNovo)
      calculaValoresOrcamento(pecasBalcao)

      if (pecasBalcao?.length > 0) {
        showSuccessMessage(`Os preços foram alterados de acordo com o novo tipo de preço: ${tipoPrecoNovo.descri_tpr}.`)
      }
    }
  }

  async function permitirDescontoByTipoPreco (tipoPrecoNovo) {
    const codigoTipoPreco = tipoPrecoNovo?.codigo_tpr || form.values.tipoPreco.codigo_tpr

    if (codigoTipoPreco) {
      let codigoConfigPermitindoUsarDesconto
      let codigoPermissaoPermitindoUsarDesconto

      switch (codigoTipoPreco) {
      case 7:
        codigoConfigPermitindoUsarDesconto = 3076
        codigoPermissaoPermitindoUsarDesconto = 180
        break
      case 8:
        codigoConfigPermitindoUsarDesconto = 3094
        codigoPermissaoPermitindoUsarDesconto = 177
        break
      case 9:
        codigoConfigPermitindoUsarDesconto = 3095
        codigoPermissaoPermitindoUsarDesconto = 178
        break
      case 10:
        codigoConfigPermitindoUsarDesconto = 3096
        codigoPermissaoPermitindoUsarDesconto = 179
        break
      }

      if (codigoConfigPermitindoUsarDesconto && codigoPermissaoPermitindoUsarDesconto) {
        const configPermiteUsarDesconto = await ConfigService.getValor(codigoConfigPermitindoUsarDesconto)
        const permissaoPermiteUsarDesconto = await PermissaoService.getByCodigo(codigoPermissaoPermitindoUsarDesconto)

        if (configPermiteUsarDesconto && permissaoPermiteUsarDesconto) {
          return true
        } else {
          return false
        }
      }
    }
    return true
  }

  function limparCampoDesconto () {
    for (const pecaBalcao of form.values.pecasBalcao) {
      pecaBalcao.perdes_peo = 0
      atualizaDescontoPeca(pecaBalcao)
      calculaValorTotalPeca(pecaBalcao)
    }

    form.setFieldValue('pecasBalcao', form.values.pecasBalcao)
  }

  return (
    <>
      <div className="border" />
      <div className="w-screen h-56 mb-4 bg-white sombra lg:flex justify-right items-center">
        <div className="md:flex w-full">
          <div className="md:flex w-full lg:ml-3">
            <p className="label-orcamento-valores text-center ">
              Valor bruto: {formatToCurrency(valorBrutoOrcamento)}
            </p>
            <p className="label-orcamento-valores text-center ">
              Descontos: {formatToCurrency(descontosOrcamento)}
            </p>
            <p className="label-orcamento-valores text-center ">
              Frete/Seguro/Despesas: {formatToCurrency(freteSeguroDespesas)}
            </p>
            <p className="label-orcamento-valores text-center ">
              Acréscimo financeiro: {formatToCurrency(form.values.acrcpg_opv)}
            </p>
          </div>
          <div className="md:flex items-center justify-center lg:w-3 lg: mb-3 h-full text-center">
            <p className="label-orcamento-total text-xl m-auto font-bold ">
              Total: {formatToCurrency(totalOrcamento)}
            </p>
          </div>
        </div>
      </div>
      <TabMenuContainer
        arrayLabelButtonNext={labelButtonsNext}
        arrayLabelButtonBack={labelButtonsBack}
        tabChangeValidator={validateTabChange}

        contentHeader={
          <div className="formgrid grid fluid">
            {form.values.cliente?.nomraz_pes ? (
              <>
                <label className="ml-5">Cliente:</label>
                <span
                  className="ml-2 underline cursor-pointer"
                  onClick={() =>
                    showPessoaModal('cliente', form.values.cliente?.cgccpf_pes, true, false, false)
                  }
                >
                  {form.values.cliente.nomraz_pes}
                </span>
              </>
            ) : (
              ''
            )}
            {form.values.consultor?.nomraz_pes ? (
              <>
                <label className="ml-5">Consultor:</label>
                <span
                  className="ml-2 underline cursor-pointer"
                  onClick={() =>
                    showPessoaModal(
                      'consultor',
                      form.values.consultor?.cgccpf_pes, false, true, false
                    )
                  }
                >
                  {form.values.consultor.nomraz_pes}
                </span>
              </>
            ) : (
              ''
            )}
            {form.values.conveniado?.nomraz_pes ? (
              <>
                <label className="ml-5">Conveniado:</label>
                <span
                  className="ml-2 underline cursor-pointer"
                  onClick={() =>
                    showPessoaModal(
                      'conveniado',
                      form.values.conveniado?.cgccpf_pes, false, false, true
                    )
                  }
                >
                  {form.values.conveniado.nomraz_pes}
                </span>
              </>
            ) : (
              ''
            )}
            <div className="form-divider mt-3"></div>
          </div>
        }
        activeIndexParaNaoMostrarContentHeader={[0]}
      >
        <TabMenuPage header="DADOS PRINCIPAIS">
          <DadosPrincipaisOpv
            form={form}
            edicaoHabilitada={isEdicaoHabilitada()}
            camposObrigatorios={camposObrigatorios}
            calculaValoresOrcamento={calculaValoresOrcamento}
            tiposPreco={tiposPreco}
            atualizaDescontoPeca={atualizaDescontoPeca}
            calculaValorTotalPeca={calculaValorTotalPeca}
            setTipoPrecoByCliente={setTipoPrecoByCliente}
            tiposmidia={tiposmidia}
            desmarcarOuMarcarPresencialSeHouverFrete={desmarcarOuMarcarPresencialSeHouverFrete}
            codigoPadraoDoClienteConsumidor={codigoPadraoDoClienteConsumidor}
            intermediadores={intermediadores}
            modalidadesVenda={modalidadesVenda}
          />
        </TabMenuPage>
        <TabMenuPage header="PEÇAS">
          <DadosPecasOpv
            form={form}
            edicaoHabilitada={isEdicaoHabilitada()}
            camposObrigatorios={camposObrigatorios}
            calculaValoresOrcamento={calculaValoresOrcamento}
            tiposPreco={tiposPreco}
            setPecaBalcaoValidator={setPecaBalcaoValidator}
            pecaBalcaoValidator={pecaBalcaoValidator}
            pecaBalcaoCamposObrigatorios={pecaBalcaoCamposObrigatorios}
            atualizaDescontoPeca={atualizaDescontoPeca}
            calculaValorTotalPeca={calculaValorTotalPeca}
            setTipoPrecoByCliente={setTipoPrecoByCliente}
            handleChangeTipoPreco={handleChangeTipoPreco}
            permitirDescontoByTipoPreco={permitirDescontoByTipoPreco}
            limparCampoDesconto={limparCampoDesconto}
            gerarVendaPerdidaPecCancelada={gerarVendaPerdidaPecCancelada}
            permissao197AlterarDescontoQtd={permissao197AlterarDescontoQtd}
          />
        </TabMenuPage>
        <TabMenuPage header="PAGAMENTO">
          <DadosPagamentoOpv
            form={form}
            edicaoHabilitada={isEdicaoHabilitada()}
            camposObrigatorios={camposObrigatorios}
            totalOrcamento={Round(totalOrcamento - form.values.acrcpg_opv, 2)}
            formasCobranca={formasCobranca}
          />
        </TabMenuPage>

        <TabMenuPage header="TRANSPORTE">
          <DadosTransporteOpv
            form={form}
            edicaoHabilitada={isEdicaoHabilitada()}
            camposObrigatorios={camposObrigatorios}
            calculaValoresOrcamento={calculaValoresOrcamento}
            modalidadesFrete={modalidadesFrete}
            estados={estados}
          />
        </TabMenuPage>
        <TabMenuPage header="OBSERVAÇÕES">
          <InformacoesAdicionaisOpv
            form={form}
            edicaoHabilitada={isEdicaoHabilitada()}
            camposObrigatorios={camposObrigatorios}
          />
        </TabMenuPage>
      </TabMenuContainer>
      <MotivoVendaPerdidaModal
        visible={visibleMotivoVendaPerdidaModal}
        onHide={() => setVisibleMotivoVendaPerdidaModal(false)}
        onConfirm={async (motivoVendaPerdida) =>
          await cancelarAposInformarMotivoVendaPerdida(motivoVendaPerdida)
        }
      />
      <EnviarWhatsAppModal
        visible={visibleEnviarWhatsAppModal}
        onHide={() => setVisibleEnviarWhatsAppModal(false)}
        onConfirm={async (mensagemEnvioWhatsApp) => await confirmarEnvioWhatsApp(mensagemEnvioWhatsApp)}
      />
      <EnviarEmailModal
        visible={visibleEnviarEmailModal}
        emailCliente={form.values.cliente?.email_pes}
        onHide={() => setVisibleEnviarEmailModal(false)}
        onConfirm={async (mensagemEnvioEmail) => await confirmarEnvioEmail(mensagemEnvioEmail)}
      />
      <Confirm
        visible={visibleConfirmarCancelar}
        onCancel={() => setVisibleConfirmarCancelar(false)}
        onConfirm={async () => await cancelarAposConfirmar()}
        title="Confirma cancelar o orçamento?"
        description="Deseja mesmo cancelar o orçamento balcão?"
      />
      <Confirm
        visible={visibleConfirmarReabrir}
        onCancel={() => setVisibleConfirmarReabrir(false)}
        onConfirm={async () => await reabrirAposConfirmar()}
        title="Confirma reabrir o orçamento?"
        description="Deseja mesmo reabrir o orçamento balcão?"
      />
      <PessoaFisicaModal
        visible={visiblePessoaFisicaModal}
        hide={() => setVisiblePessoaFisicaModal(false)}
        primaryKey={getPessoaModal(tipoPessoa)}
        apenasVisualizacao={!edicaoHabilitada}
        onSaveModal={onSaveModalCliente}
      />
      <PessoaJuridicaModal
        visible={visiblePessoaJuridicaModal}
        hide={() => setVisiblePessoaJuridicaModal(false)}
        primaryKey={getPessoaModal(tipoPessoa)}
        apenasVisualizacao={!edicaoHabilitada}
        onSaveModal={onSaveModalCliente}
      />
      <ImpressoesOrcamentoModal
        form={form}
        visibleImpressoesOrcamentoModal={visibleImpressoesOrcamentoModal}
        hide={() => setVisibleImpressoesOrcamentoModal(false)}
      />
      <RequisicaoLiberacaoRecusadasModal
        listReqRecusada={orcamentoRecusado}
        visible={visibleReqRecusadas}
        hide={() => setVisibleReqRecusadas(false)}
      />
      <RequisicaoLiberacaoPendentesModal
        listReqPendentes={orcamentoPendente}
        getOrcamentosPendentes={getOrcamentosPendentes}
        getOrcamentosRecusados={getOrcamentosRecusados}
        visible={visibleReqPendentes}
        hide={() => setVisibleReqPendentes(false)}
        form={form}
      />
      <DisponibilidadePecasModal
        visible={visibiblePecasIndisponiveisModal}
        onHide={() => { setVisibiblePecasIndisponiveisModal(false) }}
        onConfirmarVenda={enviarParaOCaixaAposValidarDisponibilidade}
        listaPecasIndisponiveis={listaPecasIndisponiveis}
      />
    </>
  )
}

export default OrcamentoBalcaoForm
