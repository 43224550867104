import React, { useEffect, useState } from 'react'
import { Crud } from '../../../../classes/NewCrud'
import { getProcessoDTO } from '../../../../dtos/processo/ProcessoDTO'
import ModeloProcessoService from '../../../../services/processo/ModeloProcessoService'
import DadosPrincipaisModeloProcesso from './main'
import { ModeloProcessoValidator } from './validator'
import AccordionContainer from '../../../../components/layout/AccordionContainer'
import AccordionPage from '../../../../components/layout/AccordionPage'
import DadosEtapas from './etapasEpendencias/ListWithModal'
import Confirm from '../../../../components/utils/Confirm'
import { useHistory } from 'react-router-dom'

const ModeloProcessoForm = ({
  form,
  edicaoHabilitada,
  camposObrigatorios,
  isNew,
  setActions
}) => {
  const [visibleConfirmarDuplicar, setVisibleConfirmarDuplicar] = useState(false)

  const history = useHistory()

  async function duplicarAposConfirmar () {
    try {
      history.push('/form-modelo-processo')

      localStorage.setItem('modelo-processo-duplicar', JSON.stringify(form.values))

      setVisibleConfirmarDuplicar(false)
    } catch (error) {
      showErrorMessage(
        error.message ||
        'Ocorreu um erro inesperado ao tentar cancelar o processo!'
      )
    }
  }

  useEffect(() => {
    setActions([
      { label: 'Duplicar', command: () => setVisibleConfirmarDuplicar(true) }
    ])
  }, [])

  useEffect(() => {
    const defineValoresParaDuplicar = async () => {
      const processoAduplicar = localStorage.getItem('modelo-processo-duplicar')

      if (!processoAduplicar) return
      if (!isNew) return

      const valores = JSON.parse(processoAduplicar)

      valores.codigo_prc = null

      for (let etapa of valores.etapas) {
        etapa.codigo_eta = null
        for (let pendencia of etapa.pendencias) {
          pendencia.codigo_etp = null
        }
      }

      await form.setValues(valores)

      localStorage.removeItem('modelo-processo-duplicar')
    }

    defineValoresParaDuplicar()
  }, [isNew])

  return (
    <>
      <Confirm
        visible={visibleConfirmarDuplicar}
        onCancel={() => setVisibleConfirmarDuplicar(false)}
        onConfirm={async () => await duplicarAposConfirmar()}
        title="Confirma duplicar o processo?"
        description="Deseja mesmo duplicar o processo?"
      />
      <AccordionContainer>
        <AccordionPage header="Informações Principais" active>
          <DadosPrincipaisModeloProcesso
            form={form}
            edicaoHabilitada={edicaoHabilitada}
          />
        </AccordionPage>
        <AccordionPage header="Etapas e pendências" active>
          <DadosEtapas
            form={form}
            edicaoHabilitada={edicaoHabilitada}
          />
        </AccordionPage>
      </AccordionContainer>
    </>
  )
}

const ModeloProcessoCrud = (props) => {
  return (
    <Crud
      {...props}
      formContent={ModeloProcessoForm}
      valida={ModeloProcessoValidator}
      modelGetDTO={getProcessoDTO}
      service={ModeloProcessoService}
      resource="modelo-processo"
      primarykeyname="codigo_prc"
      formTitle="Modelo de Processo"
    />
  )
}

export default ModeloProcessoCrud
